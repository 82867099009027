<template>
  <div
    v-if="props.isFullScreen"
    id="pano"
    style="margin-top: 10px; margin-left: 3px; width: 98%; height: 85%"
  ></div>
  <div
    v-else
    id="pano"
    style="margin-top: 10px; margin-left: 3px; width: 90vw; height: 50vh"
  />
</template>


<script setup>
import router from "../../router";
// import state from  '../../state'
// import { useMeta, useActiveMeta } from "vue-meta";
import { nextTick, onBeforeMount, onMounted, ref, defineProps } from "vue";
import { useMapStore } from "../../state";

var panorama = null;
// const lat = ref(null)
// const lon = ref(null)

const props = defineProps(["isFullScreen"]);
const mapStore = useMapStore();

function GoBack() {
  panorama = null;
  document.getElementById("pano").innerHTML = "";
  router.go(-1);
}
function MapsInit() {
  if (window.google) {
    var sv = new window.google.maps.StreetViewService();
    console.log("pano", document.getElementById("pano"));
    panorama = new window.google.maps.StreetViewPanorama(
      document.getElementById("pano"),
      {
        // position: { lat: state.lonlat[1], lng:state.lonlat[0]},
        // pov: { heading: 165, pitch: 0 },
        addressControl: false,
        linksControl: false,
        fullscreenControl: false,
        // visible: true,
      }
    );
    // panorama.setVisible(true);

    console.log("sv state lonlat", mapStore.lonlat, panorama, sv);
    sv.getPanorama(
      // 49.2630174,-122.883528 home
      {
        location: { lat: mapStore.lonlat[1], lng: mapStore.lonlat[0] },
        radius: 50,
        source: "outdoor",
      },
      processSVData
    );
  } else {
    console.log("window.google = null");
  }
}
function processSVData(data, status) {
  console.log("processSVData", data, status);
  if (status === window.google.maps.StreetViewStatus.OK) {
    console.log("ok");
    console.log("POV", data.location.pano);
    panorama.setPano(data.location.pano);
    panorama.setPov({
      heading: 60,
      pitch: 15,
    });
    console.log("POV", panorama);
    panorama.setVisible(true);
  } else {
    console.error("Street View data not found for this location.");
  }
}

onBeforeMount(async () => {
  // if(!window.google) {
  //     await useMeta({
  //         title: 'Street View',
  //         script: [
  //             {
  //                 src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs`,
  //                 async: true,
  //                 defer: true,
  //                 // callback: MapsInit(), // will declare it in methods
  //             },
  //         ],
  //     })
  // }
  // else {
  //     MapsInit()
  // }
});

onMounted(async () => {
  // if(!window.google) {
  //     await useMeta({
  //         title: 'Street View',
  //         script: [
  //             {
  //                 src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs`,
  //                 async: true,
  //                 defer: true,
  //                 // callback: MapsInit(), // will declare it in methods
  //             },
  //         ],
  //     })
  // } else {
  await nextTick();
  MapsInit();
  // }
});

// export default {
//   data() {
//   return {
//     panorama: null,
//     lat:null,
//     lon:null,
//     state
//   }
//   },
//   metaInfo() {
//     return {
//       script: [
//         {
//           src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs`,
//           async: true,
//           defer: true,
//           callback: () => this.MapsInit(), // will declare it in methods
//         },
//       ],
//     };
//   },

//   methods: {
//     GoBack() {
//       this.panorama=null
//       document.getElementById("pano").innerHTML=""
//       router.go(-1)

//     },
//     MapsInit() {
//       console.log("MapInit called");
//       var sv = new window.google.maps.StreetViewService();
//       this.panorama = new window.google.maps.StreetViewPanorama(
//         document.getElementById("pano"),
//         {
//           addressControl:false,
//           linksControl:false,
//           fullscreenControl:false,
//         }
//       );

//       console.log("sv state lonlat",state.lonlat);
//       sv.getPanorama(
//         // 49.2630174,-122.883528 home

//         { location: { lat: this.state.lonlat[1], lng:this.state.lonlat[0]}, radius: 50, source:'outdoor' },
//         this.processSVData
//       );
//     },
//     processSVData(data, status) {
//       if (status === window.google.maps.StreetViewStatus.OK) {
//         console.log("ok");
//         console.log("POV",data.location.pano)
//         this.panorama.setPano(data.location.pano);
//         this.panorama.setPov({
//          heading: 60,
//          pitch: 15,
//         });
//         console.log("POV",this.panorama)
//         this.panorama.setVisible(true);
//       } else {
//         console.error("Street View data not found for this location.");
//       }
//     },
//   },
// }
</script>
