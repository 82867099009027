export const useBookmarkColors = () => {
    const colors = [
        {
            name: 'red',
            value: '#FF0000'
        },
        {
            name: 'purple',
            value: '#800080'
        },
        {
            name: 'fuchsia',
            value: '#FF00FF'
        },
        {
            name: 'green',
            value: '#008000'
        },
        {
            name: 'yellow',
            value: '#FFFF00'
        },
        {
            name: 'blue',
            value: '#0000FF'
        },
        {
            name: 'aqua',
            value: '#00FFFF'
        },
        {
            name: 'gray',
            value: '#808080'
        }
    ]
    return colors;
}