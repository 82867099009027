import { db, db2 } from "../firebase";
import { 
    collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
    onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit 
} from "firebase/firestore";
import _  from "lodash"
// import axios from 'axios';

import { defineStore } from 'pinia'
import { useAuthStore } from '../router/useAuthStore';
import { useMapStore } from "@/state"
// import { isPlatform } from '@ionic/vue';

import { Style } from "ol/style";
import { Fill } from "ol/style";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";

export const useBookmarkStore = defineStore('bookmark', {
    // arrow function recommended for full type inference
    state: () => {
      return {
        bookmarks: [], 
        bookmarkGroups: []
      }
    },
    getters: {
        bookmarkGroupsSortByName: (state) => _.orderBy(state.bookmarkGroups, ['name'], ['asc']), 
        bookmarksByGroupId: (state) => (groupId) => {
            if(groupId === 'all')
                return state.bookmarks;
            else {
                let result = state.bookmarks.filter(bookmark=>bookmark.groupId==groupId)
                return _.orderBy(result, ['name'], ['asc'])
            }
        }, 
        ungroupedBookmarks: (state) => state.bookmarks.filter(bookmark => !("groupId" in bookmark) || bookmark.groupId === "all"),
        getColorByGroupId: (state) => (groupId) => {
            const group = state.bookmarkGroups.find(group => groupId === group.id);
            // eslint-disable-next-line no-prototype-builtins            
            if(group && group.color) {
                return group.color;
            }
            return '#006400'; // DarkGreen
            
        }, 
        getColorByGeoHash: (state) => (geoHash) => {
            const bookmark = state.bookmarks.find(bookmark => geoHash === bookmark.geoHash)
            if(bookmark) {
                const group = state.bookmarkGroups.find(group => bookmark.groupId === group.id);
                // eslint-disable-next-line no-prototype-builtins            
                if(group && group.color) {
                    return group.color;
                }
                return '#006400'; // DarkGreen
            }
            
        }, 
        checkIsBookmarked: (state) => (geoHash) => state.bookmarks.find(bookmark=>bookmark.geoHash==geoHash)?true:false,
        hasBookmark: (state) => state.bookmarks.length > 0,
    }, 
    actions: {
        async fetchBookmarks() {
            const authStore = useAuthStore()

            const querySnapshot = await getDocs(query(collection(db, "users", authStore.currentUser.uid, "bookmarks")));
            this.bookmarks = []
            querySnapshot.forEach((doc) => {
                this.bookmarks.push({id:doc.id, ...doc.data()})
            });
        },
        async addBookmark(bookmark) {
            const authStore = useAuthStore()

            if(!this.bookmarks.find(stateBookmark => stateBookmark.geoHash == bookmark.geoHash)) {
                const docRef = await addDoc(collection(db, "users", authStore.currentUser.uid, "bookmarks"), bookmark);
                this.bookmarks.push({id:docRef.id, ...bookmark})
            }
        },
        async deleteBookmark(geoHash) {
            const authStore = useAuthStore()
            
            let index = this.bookmarks.findIndex(stateBookmark => stateBookmark.geoHash == geoHash)
            if(index != -1) {
                const bookmarkRef = doc(db, "users", authStore.currentUser.uid, "bookmarks", this.bookmarks[index].id)
                await deleteDoc(bookmarkRef);
                this.bookmarks.splice(index, 1)
            }
        },
        async deleteBookmarks(bookmarks) {
            for(let bookmark of bookmarks) {
                this.deleteBookmark(bookmark.parcel_id)
            }
        },

        async fetchBookmarkGroups() {
            const authStore = useAuthStore()

            const querySnapshot = await getDocs(query(collection(db, "users", authStore.currentUser.uid, "bookmarkGroups")));
            this.bookmarkGroups = []
            querySnapshot.forEach((doc) => {
                this.bookmarkGroups.push({id:doc.id, ...doc.data()})
            });
        },
        async addBookmarkGroup(bookmarkGroup) {
            const authStore = useAuthStore()
            
            if(!this.bookmarkGroups.find(stateBookmarkGroup => stateBookmarkGroup.name == bookmarkGroup.name)) {
                const docRef = await addDoc(collection(db, "users", authStore.currentUser.uid, "bookmarkGroups"), bookmarkGroup);
                this.bookmarkGroups.push({id:docRef.id, ...bookmarkGroup})
            }
        },
        async updateBookmarkGroup(bookmarkGroup) {
            const authStore = useAuthStore()

            let index = this.bookmarkGroups.findIndex(stateBookmarkGroup => stateBookmarkGroup.id == bookmarkGroup.id)
            
            if(index != -1) {
                await updateDoc(doc(db, "users", authStore.currentUser.uid, "bookmarkGroups", bookmarkGroup.id), { name: bookmarkGroup.name }) 
                this.bookmarkGroups[index].name = bookmarkGroup.name;
            }
            
        }, 

        async updateBookmarkGroupColor(bookmarkGroup) {
            const authStore = useAuthStore()

            let index = this.bookmarkGroups.findIndex(stateBookmarkGroup => stateBookmarkGroup.id == bookmarkGroup.id)
            
            if(index != -1) {
                await updateDoc(doc(db, "users", authStore.currentUser.uid, "bookmarkGroups", bookmarkGroup.id), { color: bookmarkGroup.color }) 
                this.bookmarkGroups[index].color = bookmarkGroup.color;
            }

            this.updateBookmarkLayerFeatures(this.bookmarks, bookmarkGroup);
            
        }, 

        async moveBookmarksToGroup(bookmarks, bookmarkGroup) {
            const authStore = useAuthStore()
                        
            for(let bookmark of bookmarks) {
                let index = this.bookmarks.findIndex(stateBookmark => stateBookmark.id == bookmark.id)
                if(index != -1) {
                    await updateDoc(doc(db, "users", authStore.currentUser.uid, "bookmarks", bookmark.id), { groupId: bookmarkGroup.id }) 

                    this.bookmarks[index].groupId = bookmarkGroup.id;
                }
            }

            this.updateBookmarkLayerFeatures(bookmarks, bookmarkGroup);
        },
        async updateBookmarkLayerFeatures(bookmarks, bookmarkGroup) {
            const mapStore = useMapStore();

            mapStore.map.getLayers().forEach((lyr) => {
                if (lyr.get("name") === "Bookmarks") {
                  //set bookmark polygons on map
                  if (this.hasBookmark) {
                    bookmarks.map((bookmark) => {
                        if(bookmark.groupId === bookmarkGroup.id) {
                            var bookmarkFeature = lyr.getSource().getFeatureById(bookmark.geoHash)
                            bookmarkFeature.setStyle(new Style({
                                fill: new Fill({
                                    color: this.getColorByGroupId(bookmark.groupId),
                                }),
                            }))
                        }
                      
                    });
                  }
                }
            });
        }, 
        async deleteBookmarkGroup(group_id) {
            const authStore = useAuthStore()
            
            let index = this.bookmarkGroups.findIndex(stateBookmarkGroup => stateBookmarkGroup.id == group_id)
            if(index != -1) {
                const docRef = doc(db, "users", authStore.currentUser.uid, "bookmarkGroups", this.bookmarkGroups[index].id)
                await deleteDoc(docRef);
                this.bookmarkGroups.splice(index, 1)
            }
        },
    }
})