<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab3" href="/tabs/profile">
          <ion-icon :icon="personCircleOutline" />
          <ion-label>Profile</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab4" href="/tabs/search">
          <ion-icon :icon="searchOutline" />
          <ion-label>Search</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab1" href="/tabs/map">
          <ion-icon :icon="mapOutline" />
          <ion-label>Map</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tabpostgrouplist" href="/tabs/post/g/all">
          <ion-icon :icon="chatboxEllipsesOutline"> </ion-icon>
          <ion-badge v-if="socialStore.newPostCount > 0" color="danger">{{
            socialStore.newPostCount
          }}</ion-badge>
          <ion-label>Posts</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab5" href="/tabs/bca">
          <!-- <ion-icon :icon="searchOutline" /> -->
          <ion-icon :icon="readerOutline"></ion-icon>
          <ion-label>BCA</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import { useSocialStore } from "@/state";
import {
  IonTabBar,
  IonBadge,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonTabButton,
} from "@ionic/vue";
import {
  personCircleOutline,
  searchOutline,
  mapOutline,
  chatboxEllipsesOutline,
  layersOutline,
  readerOutline,
} from "ionicons/icons";

const socialStore = useSocialStore();
</script>

<style scoped>

#notification-icon {
  position: relative;
  width: 42px;
  top: 1px;
  right: 1px;
  overflow: visible !important;
}

#notifications-badge {
  background-color: red;
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 100%;
}
</style>
