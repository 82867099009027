<template>
<ion-modal :is-open="props.isOpen" @didDismiss="setModalClose">
    <ion-header>
        <ion-toolbar>
        <ion-title>{{props.modalTitle}}</ion-title>
        <ion-buttons slot="start">
            <ion-button @click="setModalClose">
                Close
            </ion-button>
        </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding-bottom">
        <ion-list v-if="isEditMode" style="padding-bottom: 50px;">
            <ion-item
                lines="full"
                v-for="bookmarkGroup in bookmarkStore.bookmarkGroupsSortByName"
                :key="bookmarkGroup.id"
                :style="bookmarkGroup.color?`border-left:6px solid ${bookmarkGroup.color}`:''"
                @click="() => handleMoveAction(bookmarkGroup)"  
            >
                <ion-label style="font-size: 15px">{{
                bookmarkGroup.name
                }}</ion-label>
            </ion-item>
            <ion-item button lines="full" @click="handleCreateBookmarkGroup">
                <ion-label style="font-size: 15px"
                >Create Bookmark Group</ion-label
                >
            </ion-item>
            </ion-list>
            <ion-list v-if="!isEditMode" style="padding-bottom: 50px;">
            <ion-item
                lines="full"
                @click="() => viewBookmarksByGroup(defaultBookmarkGroup)"
                :style="`border-left:6px solid #006400`"
            >
                All Bookmarks
            </ion-item>
            <ion-item
                lines="full"
                v-for="bookmarkGroup in bookmarkStore.bookmarkGroupsSortByName"
                :key="bookmarkGroup.id"
                :style="bookmarkGroup.color?`border-left:6px solid ${bookmarkGroup.color}`:''"
                @click="() => viewBookmarksByGroup(bookmarkGroup)"
            >
                <ion-label style="font-size: 15px">{{
                bookmarkGroup.name
                }}</ion-label>
            </ion-item>
            <ion-item button lines="full" @click="handleCreateBookmarkGroup">
                <ion-label style="font-size: 15px">Create Bookmark Group</ion-label>
            </ion-item>
        </ion-list>
    </ion-content>
</ion-modal>
<ion-modal :is-open="isColorOpen" @didDismiss="setColorModalClose">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="setColorModalClose()">Cancel</ion-button>
      </ion-buttons>
      <ion-title>Create New Bookmark Group</ion-title>
      <ion-buttons slot="end">
        <ion-button :strong="true" @click="createBookmarkGroup()">Create</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item>
      <ion-label position="stacked">Enter new bookmark group name</ion-label>
      <ion-input ref="newBookmarkGroupInput" @ionInput="onChangeName" v-model.trim="newBookmarkName" type="text" placeholder="Bookmark group name"></ion-input>
      <ion-note v-show="showInputError" color="danger">Please input bookmark group name</ion-note>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Choose color</ion-label>
      
      <ion-row class="scroll-x" ref="newBookmarkGroupColor">
        
        <ion-col v-for="color in bookmarkColors" :key="color.value" size="3">
          <ion-button fill="clear" @click="(evt) => handleColorOnClick(color)">
            <ion-icon slot="icon-only" :icon="ellipse" :style="`color: ${color.value}; ${newBookmarkColorValue == color.value? `border: 2px solid ${color.value}; border-radius: 25px` : ''}`"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-note v-show="showColorError" color="danger">Please select color</ion-note>
  </ion-item>
  </ion-content>
</ion-modal>
  
</template>


<script setup>
import {
  IonHeader,
  IonTitle, 
  IonToolbar,
  IonContent, 
  IonButtons,
  IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonModal,
  IonInput,
  IonNote,
  IonRow, 
  IonCol, 
  alertController,
} from "@ionic/vue";

import { ellipse } from "ionicons/icons";

import { defineProps, defineEmits, ref } from "vue";

import { useBookmarkStore } from "@/state/bookmarkStore";
import { useBookmarkColors } from "./useBookmarkColors"
import _ from "lodash";

const props = defineProps({
  isEditMode: { type: Boolean, required: true, default: false },
  isOpen: { type: Boolean, required: true, default: false },
  modalTitle: { type: String, required: false, default: 'Bookmark Groups'}
});

const emits = defineEmits(["onBookmarkGroupModalClose", "onBookmarksMove", "onBookmarkGroupSelect"]);


const bookmarkStore = useBookmarkStore();
const isColorOpen = ref(false);

const bookmarkColors = useBookmarkColors()

const showInputError = ref(false)
const showColorError = ref(false)
const newBookmarkName = ref("")
const newBookmarkColorValue = ref("")

const defaultBookmarkGroup = { id: "all", name: "All Bookmarks" };


const onChangeName = (ev) => {
  if(ev.target.value !== '') {
    showInputError.value = false
  }
}

const handleColorOnClick = (color) => {
  showColorError.value = false
  newBookmarkColorValue.value = color.value
}

const createBookmarkGroup = async () => {
  if(newBookmarkName.value == "") {
    showInputError.value = true    
  }
  
  if(newBookmarkColorValue.value == ""){
    showColorError.value = true 
  }

  if(showInputError.value || showColorError.value) return;

  await bookmarkStore.addBookmarkGroup({ name: newBookmarkName.value, color: newBookmarkColorValue.value });
  isColorOpen.value = false;
}

const setModalClose = () => {
    emits("onBookmarkGroupModalClose")
}

const setColorModalClose = () => {
  isColorOpen.value = false;

  //reset UI 
  showInputError.value = false
  showColorError.value = false
  newBookmarkName.value = ""
  newBookmarkColorValue.value = ""
}

const handleCreateBookmarkGroup = async () => {
  isColorOpen.value = true;

};

const handleMoveAction = (bookmarkGroup) => {
    emits("onBookmarksMove", bookmarkGroup);
};

const viewBookmarksByGroup = (bookmarkGroup) => {
    emits("onBookmarkGroupSelect", bookmarkGroup)
};

</script>

<style scoped>
</style>
