<template>
  <div
    v-show="ownerStore.owner && Object.prototype.hasOwnProperty.call(ownerStore.owner, 'Address')"
  >
    <!-- <ion-row class="ion-align-items-center">
      <ion-col>
        <h1 @click="goToSearch2FN1">
          <a>{{ FullName1 }}</a>
          <ion-icon slot="icon-only" :icon="search"></ion-icon>
        </h1>
        <h2 v-if="FullName2 != ''" @click="goToSearch2FN2">
          <a>{{ FullName2 }}</a>
          <ion-icon slot="icon-only" :icon="search"></ion-icon>
        </h2>
      </ion-col>
      <ion-col push="5">
        <ion-icon :icon="pencilOutline" />
      </ion-col>
    </ion-row>

    <h3 v-if="ownerStore.owner.Company != ''" @click="goToSearch2CY">
      <a>{{ ownerStore.owner.Company }}</a>
    </h3>
    <h3 v-if="ownerStore.owner.Address != ''">
      {{ ownerStore.owner.Address }}
    </h3> -->

    <ion-list-header lines="full">
      <ion-label>Owner Info</ion-label>
      <ion-button @click="setEditOwnerModalOpen(true)">Edit</ion-button>
    </ion-list-header>
    <ion-list>
      <ion-item lines="none" @click="goToSearch2FN1">
        <ion-icon :icon="personOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">
          <a>{{ FullName1 }}</a>
        </ion-label>
      </ion-item>

      <ion-item
        lines="none"
        v-if="FullName2 != ''"
        @click="goToSearch2FN2"
      >
        <ion-icon :icon="personOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">
          <a>{{ FullName2 }}</a>
        </ion-label>
      </ion-item>

      <ion-item
        lines="none"
        v-if="ownerStore.owner.Company != ''"
        @click="goToSearch2CY"
      >
        <ion-icon :icon="businessOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap" >
          <a>{{ ownerStore.owner.Company }}</a>
        </ion-label>
      </ion-item>

      <ion-item lines="none" v-if="ownerStore.owner.Address != ''">
        <ion-icon :icon="locationOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">
          {{ ownerStore.owner.Address }}
        </ion-label>
      </ion-item>
      <!-- more details... click to expand -->
      <ion-item
        lines="none"
        v-if="ownerStore.owner.ContactNo != ''"
        @click="document.location.href = 'tel:' + ownerStore.owner.ContactNo"
      >
        <ion-icon :icon="briefcaseOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap" color="primary">
          <a :href="'tel:' + ownerStore.owner.ContactNo">{{
            ownerStore.owner.ContactNo
          }}</a>
        </ion-label>
      </ion-item>
      <ion-item lines="none" v-if="ownerStore.owner.MobileTel != ''">
        <ion-icon :icon="phonePortraitSharp" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap" color="primary"
          ><a :href="'tel:' + ownerStore.owner.MobileTel">{{
            ownerStore.owner.MobileTel
          }}</a></ion-label
        >
      </ion-item>
      <ion-item lines="none" v-if="ownerStore.owner.HomeTel != ''">
        <ion-icon :icon="homeOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap" color="primary"
          ><a :href="'tel:' + ownerStore.owner.HomeTel">{{
            ownerStore.owner.HomeTel
          }}</a></ion-label
        >
      </ion-item>
      <ion-item lines="none" v-if="ownerStore.owner.Email_key3 != ''">
        <ion-icon :icon="mailOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap" color="primary"
          ><a :href="'mailto:' + ownerStore.owner.Email_key3">{{
            ownerStore.owner.Email_key3
          }}</a></ion-label
        >
      </ion-item>
      <ion-item lines="none" v-if="ownerStore.owner.Email != ''">
        <ion-icon :icon="mailOutline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap" color="primary"
          ><a :href="'mailto:' + ownerStore.owner.Email">{{
            ownerStore.owner.Email
          }}</a></ion-label
        >
      </ion-item>
    </ion-list>
    <ion-list-header lines="none">
      <h3>Summary</h3>
    </ion-list-header>
    <ion-grid class="ion-padding-start ion-padding-end">
      <ion-row>
        <!-- class="ion-justify-content-between ion-align-items-center" -->
        <ion-col>
          <ion-row class="ion-justify-content-center">
            <img
              src="@/assets/img/property1.png"
              alt="No Of Properties"
              style="width: 30px"
            />
          </ion-row>
          <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-label
              ><b>{{ ownerStore.owner.NoOfProperties }}</b></ion-label
            >
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-label color="medium"><sub>Properties</sub></ion-label>
          </ion-row>
        </ion-col>
        <ion-col>
          <ion-row class="ion-justify-content-center">
            <img
              src="@/assets/img/portfolio1.png"
              alt="Total Portfolio"
              style="width: 30px"
            />
          </ion-row>
          <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-label>$ {{ TotalPortfolioFormated }}</ion-label>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-label color="medium"><sub>Portfolio Value</sub></ion-label>
          </ion-row>
        </ion-col>
      </ion-row>
      <!-- TODO -->
    </ion-grid>

    <ion-list>
      <BasicTableApp />

      <owner-note v-if="hasOwnerNotes"></owner-note>
    </ion-list>

    <ion-list-header lines="none">
      <h3>PROFILE</h3>
      <!-- <ion-button>See All</ion-button> -->
    </ion-list-header>

    <ion-grid class="ion-padding-start ion-padding-end">
      <ion-row class="ion-align-items-center">
        <ion-col>Contact ID:</ion-col>
        <ion-col>{{ ownerStore.owner.ContactID }}</ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col>Relationship: </ion-col>
        <ion-col>{{ ownerStore.owner.Relationship }}</ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col>Market Position: </ion-col>
        <ion-col>{{ ownerStore.owner.MarketPosition }}</ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col>Investment Range: </ion-col>
        <ion-col>{{ ownerStore.owner.InvestmentRange }}</ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col>Type: </ion-col>
        <ion-col>{{ ownerStore.owner.Type }}</ion-col>
      </ion-row>

      <ion-row class="ion-align-items-center">
        <ion-col>LAST RESULT: </ion-col>
        <ion-col>{{ ownerStore.owner.LastResult }}</ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col>NEXT ACTION: </ion-col>
        <ion-col>{{ ownerStore.owner.NextAction }}</ion-col>
      </ion-row>
    </ion-grid>
    <ion-modal :is-open="isEditOwnerModalOpen" @didDismiss="setEditOwnerModalOpen(false)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Edit Contact</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="setEditOwnerModalOpen(false)">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <edit-contact
          :contact="ownerObjDb.data"
          @onUpdateSuccess="handleUpdateSuccess()"
        />
      </ion-content>
    </ion-modal>
  </div>
</template>


<script setup>
import router from "@/router";
import {
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonList,
  IonListHeader,
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonLabel,
  IonTitle,
  IonModal,
  IonHeader,
  actionSheetController,
} from "@ionic/vue";

import BasicTableApp from "./portfoliotv.vue";
import OwnerNote from "./owner-note.vue";
import EditContact from "./edit-contact.vue";
//for API call
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useOwnerStore, useSearchStore } from "@/state";

import {
  // caretForwardCircle,
  // close,
  // heart,
  // trash,
  // share,
  // pin,
  // checkmarkCircle,
  // ellipseOutline,
  // search,
  homeOutline,
  mailOutline,
  locationOutline,
  briefcaseOutline,
  personOutline,
  businessOutline,
  callOutline,
  pencilOutline,
  phonePortraitSharp,
} from "ionicons/icons";

/*
1. get parcel info from (with owner and property info)
async getParcelInfoByPID(iPID) {
            // var aOwner = {};
            // var aProperty = {};
            this.PID =iPID.substr(0, 3) + "-" + iPID.substr(3, 3) + "-" + iPID.substr(6)
            // var info = "";
            var dbURL ="https://2caz1ubxn0.execute-api.us-west-2.amazonaws.com/prod/parcel/byID/" + this.PID
            // let that=this

2.a get contacts from by ID https://us-central1-terratonepull-c669d.cloudfunctions.net/contacts
2.b update contact info (owner) using https://us-central1-terratonepull-c669d.cloudfunctions.net/contacts POST 


Q1: Are the cloud function pointing to the same data source? i.e. after edit, will we get updated data from aws api? Yes 
Q2: Do we have dev env for https://2caz1ubxn0.execute-api.us-west-2.amazonaws.com/prod/parcel/byID/? 

update First name LN 1& 2 comapny tel email address street 1 city postal code 

*/

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

const ownerStore = useOwnerStore();
const searchStore = useSearchStore();
const hasData = computed(() => ownerStore.owner != {});
const ownerObjDb = ref(null);
const hasOwnerNotes = ref(false);
const isEditOwnerModalOpen = ref(false);

const FullName1 = computed(() =>
  (ownerStore.owner.FirstName1 + " " + ownerStore.owner.LastName1).trim()
);
const FullName2 = computed(() =>
  (ownerStore.owner.FirstName2 + " " + ownerStore.owner.LastName2).trim()
);
const TotalPortfolioFormated = computed(() => {
  if(ownerStore.owner.TotalPortfolio >= 1000000) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      ownerStore.owner.TotalPortfolio = Math.round(ownerStore.owner.TotalPortfolio/100000) / 10 + 'M';
  }
  return ownerStore.owner.TotalPortfolio;
}
);


onMounted(async () => {
  if (hasData.value) {
    if (Object.prototype.hasOwnProperty.call(ownerStore.owner, "id_contacts")) {
      getPortfolioRows(ownerStore.owner.db, ownerStore.owner.id_contacts);

      getOwnerFromDB(ownerStore.owner.db, ownerStore.owner.id_contacts);
    }
  }
  // console.log(process.env.VUE_APP_CLOUD_FUNCTION_URL, "123 url cloud function");
});

const getOwnerFromDB = async (db, contactid) => {
  var dbURL = `${
    process.env.VUE_APP_CLOUD_FUNCTION_URL
  }/contacts?database=${db.trim()}&id=${contactid.trim()}`;

  await axios.get(proxyurl1 + dbURL).then((ownerObj) => {
    if (ownerObj.data && ownerObj.data.Notes) {
      hasOwnerNotes.value = true;
      ownerStore.ownerNotes = ownerObj.data.Notes;

      ownerObj.data.database = db;
    }

    ownerObjDb.value = ownerObj;

    // console.log("contacts:", ownerObjDb.value, ownerObjDb.value.database);
  });
};

const getPortfolioRows = async (db, contactid) => {
  // var dbURL = `${
  //   process.env.VUE_APP_CLOUD_FUNCTION_URL
  // }/PortfolioTreeView?database=${db.trim()}&contactID=${contactid.trim()}`;

  var dbURL = `https://us-central1-terratonepull-c669d.cloudfunctions.net/PortfolioTreeView?database=${db.trim()}&contactID=${contactid.trim()}`;

  await axios.get(proxyurl1 + dbURL).then(function (response) {
    console.log("portfolio:", response.data[0]);
    ownerStore.portfolio = response.data[0];
  });
};

const goToSearch2FN1 = () => {
  searchStore.strSearch = FullName1.value;
  openActionSheetSearch(FullName1.value, () =>
    router.push({ name: "owner-search" })
  );
};

const goToSearch2FN2 = () => {
  searchStore.strSearch = FullName2.value;
  openActionSheetSearch(FullName2.value, () =>
    router.push({ name: "owner-search" })
  );
};

const goToSearch2CY = () => {
  searchStore.strSearch = ownerStore.owner.Company;
  openActionSheetSearch(ownerStore.owner.Company, () =>
    router.push({ name: "owner-search" })
  );
};

const setEditOwnerModalOpen = (isOpen) => {
  isEditOwnerModalOpen.value = isOpen;
};

const handleUpdateSuccess = () => {
  setEditOwnerModalOpen(false);
  if (Object.prototype.hasOwnProperty.call(ownerStore.owner, "id_contacts")) {
    getOwnerFromDB(ownerStore.owner.db, ownerStore.owner.id_contacts);
  }
};

const openActionSheetSearch = async (displayText, callback) => {
  const actionSheet = await actionSheetController.create({
    buttons: [
      {
        text: `Search ${displayText}`,
        handler: () => {
          callback();
        },
      },
      {
        text: "Cancel",
        role: "cancel",
      },
    ],
  });

  await actionSheet.present();
};
</script>


<style scoped>
ion-page {
  background-color: #abc;
  height: 100%;
}

a[href^="tel:"] {
  color: var(--ion-text-color);
  text-decoration: none;
  /* margin-right: 0em; */
}
a[href^="mailto:"] {
  color: var(--ion-text-color);
  text-decoration: none;
  /* margin-right: 0.8em; */
}
</style>
