<template>
  <div
    v-show="
      propertyStore.property && Object.prototype.hasOwnProperty.call(propertyStore.property, 'Address')
    "
  >
    <ion-list-header lines="full">
      <ion-label>
        Property Info
        <br />
        <ion-text
          v-if="propertyStore.property.LO"
          @click="openActionSheetSearch"
        >
          <sub
            ><a>{{ propertyStore.property?.LO }}</a></sub
          >
        </ion-text>
      </ion-label>
    </ion-list-header>

    <ion-grid class="ion-padding-start ion-padding-end">
      <ion-row class="ion-justify-content-start">
        <ion-col>Lot Size: </ion-col>
        <ion-col>{{ propertyStore.property.LotSize }}</ion-col>
      </ion-row>
      <ion-row
        class="ion-justify-content-start"
        v-if="propertyStore.property?.BuildingStoreys != ''"
      >
        <ion-col>Building Storeys: </ion-col>
        <ion-col>{{ propertyStore.property.BuildingStoreys }}</ion-col>
      </ion-row>
      <ion-row
        class="ion-justify-content-start"
        v-if="propertyStore.property?.NoOfUnit != ''"
      >
        <ion-col>No Of Units: </ion-col>
        <ion-col>{{ propertyStore.property.NoOfUnit }}</ion-col>
      </ion-row>
      <ion-row
        class="ion-justify-content-start"
        v-if="propertyStore.property?.GrossTax != ''"
      >
        <ion-col>Gross Tax: </ion-col>
        <ion-col>{{ propertyStore.property.GrossTax }}</ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-col>Assessment (year): </ion-col>
        <ion-col
          >{{ propertyStore.property.AssessmentTotalValue }} ({{
            propertyStore.property.AssessmentYear
          }})</ion-col
        >
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-col>Land: </ion-col>
        <ion-col>{{ propertyStore.AssessmentLand }}</ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-col>Buildings: </ion-col>
        <ion-col>{{ propertyStore.AssessmentBuilding }}</ion-col>
      </ion-row>
    </ion-grid>

    <!-- <ion-item
      lines="none"
      button
      v-if="propertyStore.property.Pid"
      @click="goToSearch2TL"
    >
      Search by Title (PID)
    </ion-item> -->
    <!-- <ion-button @click="go2propPosts">Tagged Posts</ion-button> -->
  </div>
</template>
<script setup>
// import { onMounted, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useSocialStore, usePropertyStore, useSearchStore } from "@/state";
import {
  IonList,
  IonItem,
  IonListHeader,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonButton,
  IonText,
  actionSheetController,
} from "@ionic/vue";
import { search } from "ionicons/icons";

const router = useRouter();
const socialStore = useSocialStore();
const searchStore = useSearchStore();

const propertyStore = usePropertyStore();

// const go2propPosts = () => {
//   socialStore.filterPostAddress = propertyStore.property.Address;
//   socialStore.filterPostPid = propertyStore.property.Pid;

//   router.push({ name: "post" });
// };

const openActionSheetSearch = async () => {
  const actionSheet = await actionSheetController.create({
    buttons: [
      {
        text: `Search Title`,
        handler: () => {
          searchStore.strSearch = propertyStore.property.Pid;
          router.push({ name: "property-search" });
        },
      },
      {
        text: `Search ${propertyStore.property.LO}`,
        handler: () => {
          searchStore.strSearch = propertyStore.property.LO;
          router.push({ name: "property-search" });
        },
      },
      {
        text: "Cancel",
        role: "cancel",
      },
    ],
  });

  await actionSheet.present();
};
</script>
<style scoped>
ion-page {
  background-color: #777;
  height: 100%;
}
</style>
