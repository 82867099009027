<template>
  <!-- <ion-grid>
    <ion-row class="ion-align-items-end" v-if="mapStore.selectedAddress != ''">
      <ion-col size="10">
        <ion-item lines="none">
          <location-tag
            :style="post.tagParcel ? 'color: blue' : 'color: grey'"
            :address="mapStore.selectedAddress"
          >
          </location-tag>
        </ion-item>
      </ion-col>
      <ion-col size="2">
        <div class="tag_parcel" lines="none">
          <ion-checkbox
            class="tagParcelcb"
            slot="start"
            @update:modelValue="post.tagParcel = $event"
            :modelValue="post.tagParcel"
          >
          </ion-checkbox>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-end">
      <ion-col size="12">
        <ion-item lines="none">
          <ion-input
            class="post-title"
            v-model.trim="post.title"
            placeholder="Title"
            inputmode="text"
            @click="isOpen = true" 
          ></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-textarea
            class="post-input"
            v-model.trim="post.content"
            inputmode="text"
            placeholder="Write a post ...."
            required="true"
            @click="isOpen = true"
          ></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-end group-control">
      <ion-col size="12">
        <ion-item lines="none">
          <ion-button fill="clear" @click="setIsPostGroupModalOpen(true)">{{
            displayGroupName
          }}</ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-end">
      <ion-col size="2" size-xs="4">
        <ion-item lines="none">
          <ion-button
            expand="block"
            color="light"
            @click="attachMedia"
            class="btn-attach"
          >
            <ion-icon :icon="attachOutline" slot="icon-only"></ion-icon>
          </ion-button>
          <input
            id="photoUpload"
            type="file"
            ref="photoUpload"
            accept="image/*"
            @change="photoUploadChange"
          />
          <input
            id="fileUpload"
            type="file"
            ref="fileUpload"
            @change="fileUploadChange"
          />
        </ion-item>
      </ion-col>
      <ion-col size="10" size-xs="8">
        <ion-item lines="none">
          <ion-button
            expand="block"
            color="primary"
            @click="() => createPost()"
            :disabled="post.content === ''"
            class="btn-addPost"
          >
            Add Post 
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid> -->
  <!-- attached files -> open modal for media preview and continue type post -->
  <ion-modal :is-open="isPostGroupModalOpen" @didDismiss="setIsPostGroupModalOpen(false)">
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Post Group</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="setIsPostGroupModalOpen(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <post-group-list
        @handleGroupItemClick="(selectedGroup) => selectGroup(selectedGroup)"
        :isShowAllPosts="false"
      />
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="isOpen" @didDismiss="createPostModalOnClose">
    <ion-header>
      <ion-toolbar>
        <ion-title>Add Post</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="dismiss()">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding safe-area-bottom">
      <ion-row class="ion-align-items-end" v-if="mapStore.selectedAddress != ''">
      <ion-col size="10">
        <ion-item lines="none">
          <location-tag
            :style="mapStore.selectedAddress != '' ? 'color: blue' : 'color: grey'"
            :address="mapStore.selectedAddress"
          >
          </location-tag>
        </ion-item>
      </ion-col>
      <ion-col size="2">
        <div class="tag_parcel" lines="none">
          <ion-checkbox
            class="tagParcelcb"
            slot="start"
            :checked="true"
            @ionChange="(e) => {
              post.tagParcel = e.detail.checked
            }"
          >
          </ion-checkbox>
        </div>
      </ion-col>
    </ion-row>
      <ion-img
        v-if="photoAttachment"
        class="ion-padding attachPhoto"
        v-memo="[photoAttachment]"
        :src="photoUrl"
      ></ion-img>

      <ion-input
        class="post-title ion-padding-top"
        v-model.trim="post.title"
        placeholder="Title"
        inputmode="text"
      ></ion-input>
      <ion-textarea
        class="post-input"
        v-model.trim="post.content"
        inputmode="text"
        placeholder="Write a post ...."
        rows="1"
        required="true"
      ></ion-textarea>
      <ion-button fill="clear" @click="setIsPostGroupModalOpen(true)">{{
        displayGroupName
      }}</ion-button>
      <ion-row
        v-if="fileAttachment"
        v-memo="[fileAttachment]"
        class="ion-align-items-center"
      >
        <ion-col size="10">
          <ion-icon :icon="attachOutline"></ion-icon>
          <ion-text>{{ attachmentName }}</ion-text>
        </ion-col>
        <ion-col size="2">
          <ion-button fill="clear"
            @click="fileAttachment = null"
          >
            <ion-icon :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <input
        id="photoUploadModal"
        type="file"
        ref="photoUploadModal"
        accept="image/*"
        @change="photoUploadChange"
      />
      <input
        id="fileUploadModal"
        type="file"
        ref="fileUploadModal"
        @change="fileUploadChange"
      />
      <ion-row class="ion-padding-bottom">
        <ion-col size="3">
        <ion-button expand="block" color="light" @click="attachMedia">
          <ion-icon :icon="attachOutline" slot="icon-only"></ion-icon>
        </ion-button>
        </ion-col>
        <ion-col size="9">
        <ion-button
          expand="block"
          color="primary"
          @click="createPost"
          :disabled="post.content === '' || isLoading"
        >
          Add Post
          <ion-spinner v-if="isLoading"></ion-spinner>
          <!-- <ion-icon :icon="send" slot="icon-only"></ion-icon> -->
        </ion-button>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { reactive, ref, computed, defineEmits, defineProps, watch, toRefs } from "vue";
import LocationTag from "@/components/social-page/location-tag.vue";
import PostGroupList from "@/components/social-page/post-group-list.vue";

import { useSocialStore, useMapStore } from "@/state";
import {
  send,
  informationCircle,
  attachOutline,
  closeOutline,
} from "ionicons/icons";
import {
  IonTextarea,
  IonInput,
  IonLabel,
  IonIcon,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonToolbar,
  IonHeader,
  IonContent,
  IonModal,
  IonTitle,
  IonButtons,
  IonImg,
  IonText,
  IonCheckbox,
  IonSpinner, 
  toastController,
  onIonViewWillEnter,
  onIonViewDidEnter,
  onIonViewWillLeave,
  onIonViewDidLeave,
  modalController,
  actionSheetController,
} from "@ionic/vue";
import { usePostGroupStore } from "@/state/postGroupStore";

const isPostAdded = ref(false);
const isPostGroupModalOpen = ref(false);

const fileUpload = ref(null);
const photoUpload = ref(null);

const fileUploadModal = ref(null);
const photoUploadModal = ref(null);

const mapStore = useMapStore();
const socialStore = useSocialStore();
const postGroupStore = usePostGroupStore();

const photoAttachment = ref(null);
const fileAttachment = ref(null);


const photoUrl = computed(() => {
  
  return photoAttachment.value?URL.createObjectURL(photoAttachment.value): null;
});
const photoName = computed(() => {
  return photoAttachment.value?photoAttachment.value.name: null;
});

const attachmentUrl = computed(() => {
  return fileAttachment.value?URL.createObjectURL(fileAttachment.value): null;
});
const attachmentName = computed(() => {
  return fileAttachment.value?fileAttachment.value.name: null;
});
const selectedGroup = ref(null);
const displayGroupName = computed(() =>
  selectedGroup.value ? selectedGroup.value.name : "Select post group"
);

const emits = defineEmits(["displayPostList"]);
//TODO: tagParcel
const post = reactive({
  title: "",
  content: "",
  tagParcel: mapStore.selectedAddress != "",
  groupId: "",
});

let props = defineProps(["showCreate"]);
const isOpen = ref(false);
// const props = defineProps({
//   showCreate: { type: Boolean, required: false, default: false },
// });

watch(() => {
  const { showCreate } = toRefs(props);
  isOpen.value = showCreate.value;
  post.tagParcel = (mapStore.selectedAddress != "")
})

// isOpen.value = props.showCreate;

const attachMedia = async () => {
  // isOpen.value = true;
  // fileUpload.value.click();

  const actionSheet = await actionSheetController.create({
    header: "Select File Type",
    // subHeader: "Example subheader",
    buttons: [
      {
        text: "Photo",
        handler: () => {
          if(isOpen.value) {
            photoUploadModal.value.click();  
          } else {
            photoUpload.value.click();
          }
          
        },
      },
      {
        text: "File",
        handler: () => {
          if(isOpen.value){
            fileUploadModal.value.click();
          } else {
            fileUpload.value.click();
          }
          
        },
      },
      {
        text: "Cancel",
        role: "cancel",
      },
    ],
  });

  await actionSheet.present();

  const res = await actionSheet.onDidDismiss();
  // console.log(JSON.stringify(res, null, 2));
};

const outInput = (event) => {
  // to do
  // let container =  document.querySelector('.pane');
};
const focusInput = (event) => {
  // to do
  // // debugger
  //     let total = 0;
  //     let container = 'ion-content';
  //     const _rec = (obj) => {
  //         total += obj.offsetTop;
  //         const par = obj.offsetParent;
  //         if (par && par.localName !== 'ion-content') {
  //             _rec(par);
  //         } else {
  //             container = par;
  //         }
  //     };
  //     _rec(event.target);
  //     setTimeout(() => {
  //       container.scrollToPoint(0, total - 50, 400);
  //     }, 500);
};

const MAX_PHOTO_SIZE_MB = 10
const MAX_PHOTO_SIZE = MAX_PHOTO_SIZE_MB * 1000 * 1000; // 10MB
const MAX_FILE_SIZE_MB = 20
const MAX_FILE_SIZE= MAX_FILE_SIZE_MB * 1000 * 1000// 20MB

const photoUploadChange = async (e) => {
  photoAttachment.value = e.target.files[0];

if(photoAttachment.value.size > MAX_PHOTO_SIZE) {
    photoAttachment.value = null;

    const toast = await toastController.create({
      message: `Please select photo less than ${MAX_PHOTO_SIZE_MB}MB.`,
      duration: 3000,
    });
    await toast.present();
  }
  isOpen.value = true;
};

const fileUploadChange = async (e) => {
  fileAttachment.value = e.target.files[0];

  if(fileAttachment.value.size > MAX_FILE_SIZE) {
    fileAttachment.value = null;

    const toast = await toastController.create({
      message: `Please select file less than ${MAX_FILE_SIZE_MB}MB.`,
      duration: 3000,
    });
    await toast.present();
  }

  isOpen.value = true;
};

const isLoading = ref(false)

const createPost = async () => {
  isLoading.value = true

  isPostAdded.value = await socialStore.addPost(
    post,
    fileAttachment,
    photoAttachment
  )
    
    const toast = await toastController.create({
      message: isPostAdded.value ? "Post sent." : "Post not sent",
      duration: 2000,
    });
    await toast.present();

    if(isPostAdded.value) {
      // isOpen.value = false;
      
      // console.log("isOpen.value", isOpen.value)
      
      // fileAttachment.value = null;
      // photoAttachment.value = null;
      selectedGroup.value = null;

      if(!isOpen.value) {
        // prevent modal hide by v-if after emitting events
        emits("displayPostList");
      }

      isLoading.value = false
      emits("displayPostList")
    }

};

const createPostModalOnClose = () => {
  // emits("displayPostList");
  // if(isPostAdded.value)
  //   emits("displayPostList");
}

//TODO: button remove group
const selectGroup = (group) => {
  selectedGroup.value = group;
  post.groupId = group.id;
  setIsPostGroupModalOpen(false);
};

const setIsPostGroupModalOpen = (isOpen) => {
  if (isOpen) {
    postGroupStore.fetchPostGroups();
  }

  isPostGroupModalOpen.value = isOpen;
};

const dismiss = () => {
  
  
  fileAttachment.value = null;
  photoAttachment.value = null;
  selectedGroup.value = null;
  console.log("dismiss", isOpen.value, fileAttachment.value, photoAttachment.value, selectedGroup.value)
  console.log("dismiss2", photoUrl.value, photoName.value, attachmentUrl.value, attachmentName.value, displayGroupName.value)
  isOpen.value = false;
  emits("displayPostList");
};
</script>
<style scoped>
#fileUpload,
#photoUpload,
#fileUploadModal,
#photoUploadModal {
  display: none;
}

.ptextarea {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  width: 80%;
  resize: none;
}

.post-title {
  border-radius: var(--border-radius);
  display: block;
  width: 100%;
  border: 0;
  background: var(--background);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.03);
  --border-radius: 10px;
  --box-shadow: none;
  --color: var(--ion-text-color, #000);
  --icon-color: var(--ion-color-step-600, #666666);

  padding-left: 5px !important;
  padding-right: 5px !important;
}
.post-input {
  border-radius: var(--border-radius);
  display: block;
  width: 100%;
  border: 0;
  outline: none;
  background: var(--background);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
  --border-radius: 10px;
  --color: var(--ion-text-color, #000);
  --icon-color: var(--ion-color-step-600, #666666);

  margin-top: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
/* 
.post-input {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  border-radius: var(--border-radius);
  display: block;
  width: 100%;
  border: 0;
  outline: none;
  background: var(--background);
  font-family: inherit;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
  --border-radius: 10px;
  --box-shadow: none;
  --cancel-button-color: var(--ion-color-primary, #3880ff);
  --clear-button-color: var(--ion-color-step-600, #666666);
  --color: var(--ion-text-color, #000);
  --icon-color: var(--ion-color-step-600, #666666);

  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 60px;
  contain: strict;
} */
.tagParcelcb {
  --size: 18px;
}

.tag_parcel {
  margin-bottom: 0.65rem;
}

.btn-addPost {
  width: 100%;
  height: 80%;
}

.btn-attach {
  width: 100%;
  height: 80%;
}

.group-control {
  margin-top: -6px;
  margin-bottom: -8px;
}

.item {
  --min-height: 30px;
}

.safe-area-bottom {
  margin-bottom: var(--ion-safe-area-bottom);
  --padding-bottom: 66px;
}

.attachPhoto {
  height: 20em;
}
</style>
