<template>
  <ion-item lines="none" style="margin:0 -20px; border-bottom: 0.5rem solid lightgray">
    <ion-label>
      <ion-grid class="ion-align-items-center post-padding-horizontal">
        <ion-row v-if="post.centre">
          <h3 class="" @click="oneClick(post.id)">
            <location-tag
              :address="post.propinfo?.Address"
              :centre="post.centre"
            ></location-tag>
          </h3>
        </ion-row>
        <ion-row class="ion-align-items-center" style="padding-top:3px;">
            <ion-avatar style="width: 40px; height: 40px;">
              <img
                class="list-item__thumbnail"
                :src="
                  post.userPhotoURL
                    ? post.userPhotoURL
                    : 'http://ui-avatars.com/api/?rounded=true&name=' +
                      post.userName
                "
              />
            </ion-avatar>
          <ion-col class="post-padding-horizontal">
            <p class="">{{ post.userName }} 
              <ion-text class="ion-padding-start" color="medium">
                <sub>{{ formatDate(post?.createdOn) }}</sub>
              </ion-text>
            </p>
            <a
              v-if="post.groupId"
              class=""
              @click="
                () =>
                  router.push({
                    name: 'post-by-group',
                    params: { postGroupId: post.groupId },
                  })
              "
            >
              <ion-row class="ion-align-items-center" style="padding-top:3px">
                <ion-icon :icon="enterOutline"></ion-icon>
                <ion-text style="padding-left: 2px;">{{ postGroupStore.getPostGroupById(post.groupId)?.name }}</ion-text>  
              </ion-row>
            </a>
            
          </ion-col>

            <ion-icon
              :icon="ellipsisVertical"
              @click="openActionSheet(post)"
            ></ion-icon>
        </ion-row>
      </ion-grid>

      <div class="post-padding">
        <h4 v-if="post.title"><i>{{ post.title }}</i></h4>
        <p class="post_content">
          <ion-text @click="viewPostComment(post)" v-html="displayContent"></ion-text>
          <ion-text color="medium" @click="handleContentDisplayLength">
            <sub>{{ displayContentEllipsis }}</sub>
          </ion-text>
        </p>
      </div>


      <div 
        v-if="post.photoURL" 
        :class="`${isImgNeedCrop? isImageClicked?'':'img-item' :''}`"
        :style="`${isPlatform('tablet')?'margin: 8px':''}`" >
        <div :class="`${isImgNeedCrop? isImageClicked? '':'img-wrap' :''} `" @click="onClickImage(post)">
          <ion-img
            class="post-image"
            v-memo="[post.photoURL]"
            :src="post.photoURL"
            @ionImgDidLoad="imgDidLoad"
          ></ion-img>
          <ion-text v-if="isImgNeedCrop" color="medium" style="text-align: center;">{{isImageClicked?"":"View Full Image"}}</ion-text>
        </div>
      </div>      
      
      <div v-if="post.fileURL" class="post-padding-horizontal">
        <ion-text color="medium">
          <small>Attachment:</small> 
          <a :href="post.fileURL">{{ post.fileName }}</a>
          </ion-text>
      </div>
      <div class="post-padding-top">
        <slot />
      </div>
      <p class="post-padding-horizontal">
        <span
          @click="displayUserLikesModal(post.id)"
          style="font-weight: bold"
          >{{ countDisplay(post.likes, "like") }}</span
        >
        .
        <span class="ioin-float-end" style="font-weight: bold">{{
          countDisplay(post.comments, "comment")
        }}</span>
      </p>
    </ion-label>
  </ion-item>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  IonThumbnail,
  IonAvatar,
  IonCheckbox,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonLabel,
  IonRadio,
  IonToggle,
  IonModal,
  IonContent,
  IonImg,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  actionSheetController,
  isPlatform
} from "@ionic/vue";
import {
  ellipsisVertical,
  trash,
  share,
  caretForwardCircle,
  heart,
  close,
  enterOutline,
} from "ionicons/icons";
import moment from "moment";
import LocationTag from "@/components/social-page/location-tag.vue";
import { useSocialStore } from "@/state";
import { Share } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
import { usePostGroupStore } from "@/state/postGroupStore";

const router = useRouter();
const strDomain = process.env.BASE_URL;

const props = defineProps({
  post: { type: Object, required: true },
  maxWord: { type: Number, required: false, default: 999 },
});

const emits = defineEmits(["displayUserPostLikesModal"]);

const postGroupStore = usePostGroupStore();
const socialStore = useSocialStore();
const isShowFullContent = ref(false);

const viewPostComment = (post) => {
  socialStore.selectedPost = post;
  router.push({ name: "post-comment", params: { postId: post.id } });
};

const displayContent = computed(() => {
  // console.log(props.post, !props.post, !props.post.content)
  if(!props.post.content) {
    return ""
  }

  const urlify = (text) => {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return ' <a href="' + url + '" target="_blank">' + url + '</a> ';
      }
    )
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

// var html = urlify(text);
  return isShowFullContent.value
    ? urlify(props.post.content)
    : urlify(props.post.content?.substring(0, props.maxWord));
});

const displayContentEllipsis = computed(() => {
  if(!props.post.content) {
    return ""
  }
  
  if (props.post.content.length > props.maxWord) {
    return isShowFullContent.value ? " ^ show less" : "... more";
  } else {
    return "";
  }
});

const handleContentDisplayLength = () => {
  isShowFullContent.value = !isShowFullContent.value;
};

const countDisplay = computed(() => (counts, strDisplay) => {
  if (!counts || counts == 0) {
    return `0 ${strDisplay}`;
  } else if (counts > 1) {
    return `${counts} ${strDisplay}s`;
  } else {
    return `${counts} ${strDisplay}`;
  }
});

const formatDate = computed(() => (val) => {
  if (!val) {
    return "-";
  }
  let date = val.toDate();
  return moment(date).fromNow();
});

const copy = (postContent) => {
  navigator.clipboard
    .writeText(postContent)
    .then(() => {
      alert("Post content is copied.");
      console.log("Text is on the clipboard.");
    })
    .catch((e) => {
      console.error(e);
    });
};

const openActionSheet = async (post) => {
  let asButtons = [];

  if (socialStore.canPostBeDeleted(post.id)) {
    asButtons.push({
      text: "Delete",
      role: "destructive",
      id: "delete-button",
      data: {
        type: "delete",
      },
      handler: () => {
        socialStore.softDeletePost(post.id);
        console.log("Delete clicked");
      },
    });
  }

  asButtons.push(
    {
      text: "Share",
      data: 10,
      handler: () => {
        sharePost(post);
        console.log("Share clicked");
      },
    },
    {
      text: "Copy",
      data: "Data value",
      handler: () => {
        copy(post.content);
        console.log("Copied");
      },
    },
    // {
    //   text: "Follow",
    //   handler: () => {
    //     console.log("Favorite clicked");
    //   },
    // },
    {
      text: "Cancel",
      role: "cancel",
      handler: () => {
        console.log("Cancel clicked");
      },
    }
  );
  const actionSheet = await actionSheetController.create({
    header: "Post Actions",
    cssClass: "my-custom-class",
    buttons: asButtons,
  });
  await actionSheet.present();
};

const sharePost = async (post) => {
  if (Capacitor.isNativePlatform()) {
    await Share.share({
      title: post.title,
      text: post.content,
      url: `${strDomain}/tabs/post/${post.id}/comments`,
    });
  } else {
      navigator.clipboard
    .writeText(`${strDomain}/tabs/post/${post.id}/comments`)
    .then(() => {
      alert("Post url is copied.");
    })
    .catch((e) => {
      console.error(e);
    });
  }
};

// const isLikesModalOpen = ref(false);
const displayUserLikesModal = (postId) => {
  // isLikesModalOpen.value = true;
  socialStore.fetchLikedUserList(postId);
  emits("displayUserPostLikesModal");
};

const isImageClicked = ref(false)

const onClickImage = (post) => {
  console.log("onClickImage", isImageClicked.value)
  isImageClicked.value = !isImageClicked.value
}

const isImgNeedCrop = ref(false)
const imgDidLoad = (event) => {
  // console.log("imgDidLoad", event, event.target.clientHeight)
  // console.log("imgDidLoad2", event.target.width)

  if(event.target.clientHeight >= 350){
    isImgNeedCrop.value = true
  }

}
</script>

<style scoped>
.post_content {
  color: black;
  font-size: 1em;
  padding-top: 8px;
  padding-bottom: 8px;
  word-break: normal;
  white-space: normal;
}

/* .info-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
} */

.info-row ion-icon:last-child {
  margin-left: auto;
}

/* ion-item crashes webkit; fix found in: https://github.com/ionic-team/ionic-framework/issues/25760 */
ion-item::part(native)::after {
  z-index: 0;
  pointer-events: none;
}
ion-item {
  border-bottom: 1px;
}
a {
  font-size: 0.8em;
}

.minImage {
  height: 30vh;
}


.img-item {
  margin: 0;
  /* padding: 1em; */
  position: relative;
  max-width: 600px;
  height: 350px;
  overflow: hidden;
}
.img-item .img-wrap:before {
  content: '';
  background-image: linear-gradient(to top, rgba(239,239,239,255), rgba(239,239,239,0));
  position: absolute;
  height: 100px;
  right: 0;
  bottom: 0;
  left: 0;
}

.img-item .img-wrap:after {
  content: '';
  display: block;
  height: 100vh;
}
.img-item .img-wrap>ion-text {
  position: absolute;
  text-align: center;
  right: 0;
  bottom: 0;
  left: 0;
}

.img-wrap img {
  width: 100%;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
  -ms-interpolation-mode: bicubic;
}

.post-image {
  /* border-style: solid;
  border-color: #909090;
  border-width: 2px; */
  max-width: 600px;
}

.post-padding {
  padding:8px;
}
.post-padding-horizontal {
  padding-left:8px;
}
.post-padding-top {
  padding-top:8px;
}
.post-padding-bottom {
  padding-bottom:8px;
}

</style>

