<template>

  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="onApply">Apply</ion-button>
        <ion-button color="dark" @click="onCancel">Cancel</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-bottom">
    <ion-list>
      <ion-item>
        <ion-label>Year</ion-label>
        <ion-select
          interface="popover"
          @ionChange="(ev) => handleYearOnChange(ev.detail.value)"
          :value="transFilterStore.selectedYear"
        >
          <ion-select-option v-for="year in years" :key="year" :value="year">{{
            year
          }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Month</ion-label>
        <ion-text slot="end">{{ selectedMonthsDisplay }}</ion-text>
      </ion-item>
      <ion-item>
        <ion-range
          min="0"
          max="11"
          step="1"
          :value="selectedMonths"
          snaps="true"
          dual-knobs
          pin
          :pin-formatter="monthRangeFormatter"
          @ionKnobMoveEnd="(ev) => handleMonthsOnChange(ev.detail.value)"
        >
        </ion-range>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Sold Price</ion-label>
        <ion-text slot="end">{{ priceRangeDisplay }}</ion-text>
      </ion-item>
      <ion-item>
        <ion-range
          min="0"
          :max="maxPrice"
          :step="500000"
          :value="selectedPrices"
          dual-knobs
          pin
          :pin-formatter="priceRangeFormatter"
          @ionChange="(ev) => handlePriceRangeOnChange(ev.detail.value)"
        >
        </ion-range>
      </ion-item>

      
      <ion-accordion-group :multiple="true" :value="['assetTypes', 'saleTypes']">
        <ion-accordion value="assetTypes">
          <ion-item slot="header" lines="none">
            <ion-label>Asset Types</ion-label>
          </ion-item>
          <ion-list slot="content">
            <ion-item v-for="type in recentTransAssetTypes" :key="type.id" lines="none">
              <ion-label>{{ type.name }}</ion-label>
              <ion-checkbox
                slot="start"
                @ionChange="
                  (ev) => handleAssetTypeChange(ev.detail.checked, type.code)
                "
                :checked="assetTypeIsChecked(type.code)"
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="saleTypes">
          <ion-item slot="header">
            <ion-label>Sale Types</ion-label>
          </ion-item>
          <ion-list slot="content">
            <ion-item
              v-for="type in recentTransSaleTypesSelections"
              lines="none"
              :key="type.id"
            >
              <ion-label>{{ type.name }}</ion-label>
              <ion-checkbox
                slot="start"
                @ionChange="
                  (ev) => handleSaleTypeChange(ev.detail.checked, type.name)
                "
                :checked="saleTypeIsChecked(type.name)"
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
    </ion-list>
  </ion-content>
</template>

<script setup>
import {
  // IonicSlides,
  // IonSlides, IonSlide,
  // IonPage, IonModal,
  // IonContent,
  // IonThumbnail,
  // IonAvatar,
  IonCheckbox,
  // IonInput,
  IonItem,
  // IonItemOption,
  // IonItemOptions,
  // IonItemSliding,
  IonList,
  // IonRadio,
  // IonToggle,
  IonAccordion,
  IonAccordionGroup,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonText,
  IonPopover,
  IonDatetime,
  IonLabel,
  IonCard,
  IonCardTitle,
  IonButton,
  IonButtons,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  modalController,
  // onIonViewWillEnter, onIonViewDidEnter, onIonViewWillLeave, onIonViewDidLeave
} from "@ionic/vue";
import { computed, reactive, ref, defineEmits } from "vue";
import { format, parseISO, getDate, getMonth, getYear } from "date-fns";
import axios from "axios";
import _ from "lodash";
import * as olControl from "ol/control";
import * as olStyle from "ol/style";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import { fromLonLat } from "ol/proj";
import { useMapStore } from "@/state";
import { useTransactionFilterStore } from "./useTransactionFilterStore"

const mapStore = useMapStore();
const transFilterStore = useTransactionFilterStore()

let recentTrans = [];
const transDisplay = ref([]);
// let recentTransCache = [];
// const isLocalDev = false;
// const isLocalDev =
//   location.hostname == "localhost" || location.hostname == "127.0.0.1";
// const realnetFileType = isLocalDev ? ".json" : ".json.gz";

const realnetFileType = ".json";

const years = ref([]);
const mostRecentTransYear = 2021;
const numTransYears = 5;
for (
  var transYear = mostRecentTransYear;
  transYear > mostRecentTransYear - numTransYears;
  transYear--
) {
  years.value.push(transYear);
}

// const selectedYear = ref(transFilterStore.selectedYear);

axios
  .get(`/assets/data/RealnetOutput${transFilterStore.selectedYear}${realnetFileType}`)
  .then((response) => {
    if (response && response.status === 200) {
      recentTrans = response.data;
      // recentTransCache.push({ transYear: recentTrans });
    }
  });

const handleYearOnChange = (year) => {
  if (year) {
    transFilterStore.selectedYear = year
    // selectedYear.value = year;
    console.log('year', transFilterStore.selectedYear )
    axios
      .get(`/assets/data/RealnetOutput${year}${realnetFileType}`)
      .then((response) => {
        if (response && response.status === 200) {
          recentTrans = response.data;
          console.log(response.data)
          // recentTransCache.push({ [year]: recentTrans });
        }
      });
  }
};

const selectedMonths = reactive({
  lower: transFilterStore.selectedMonthsLower,
  upper: transFilterStore.selectedMonthsUpper,
});

const selectedMonthsDisplay = computed(
  () =>
    `From ${monthRangeFormatter(transFilterStore.selectedMonthsLower)} to ${monthRangeFormatter(
      transFilterStore.selectedMonthsUpper
    )}`
);
const handleMonthsOnChange = (data) => {
  // selectedMonths.lower = data.lower;
  // selectedMonths.upper = data.upper;

  transFilterStore.selectedMonthsLower = data.lower
  transFilterStore.selectedMonthsUpper = data.upper

  // var dateStr = that.recentTrans[i].EventDate.substr(5,2);
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthRangeFormatter = (i) => monthNames[i];

const maxPrice = 100000000;
const selectedPrices = reactive({
  lower: transFilterStore.selectedPricesLower,
  upper: transFilterStore.selectedPricesUpper,
});

const priceRangeDisplay = computed(
  () =>
    `From ${priceRangeFormatter(transFilterStore.selectedPricesLower)} to ${priceRangeFormatter(
      transFilterStore.selectedPricesUpper
    )}`
);
const handlePriceRangeOnChange = (data) => {
  transFilterStore.selectedPricesLower = data.lower
  transFilterStore.selectedPricesUpper = data.upper
};

const priceRangeFormatter = (p) =>
  `$${p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

const recentTransClusterDistance = 40;
const recentTransClusterRadius = 15;

const recentTransAssetTypes = [
  {
    id: 0,
    code: "HOT",
    name: "Hotel",
  },
  {
    id: 1,
    code: "ICI",
    name: "ICI Land",
  },
  {
    id: 2,
    code: "IND",
    name: "Industrial",
  },
  {
    id: 3,
    code: "APT",
    name: "Multi-Family",
  },
  {
    id: 4,
    code: "OFF",
    name: "Office",
  },
  {
    id: 5,
    code: "RET",
    name: "Retail",
  },
  {
    id: 6,
    code: "RLN",
    name: "Residential Land",
  },
];

const assetTypeIsChecked = (type) => {
  let index = transFilterStore.selectedAssetTypes.findIndex(t => t == type)
  return (index != -1)? true: false;
}
const handleAssetTypeChange = (isChecked, type) => {
  // recentTransAssetTypes[type.id].isChecked = isChecked;

  if(isChecked) {
    transFilterStore.selectedAssetTypes.push(type)
  } else {
    let index = transFilterStore.selectedAssetTypes.findIndex(t => t == type)
    if(index != -1) {
        transFilterStore.selectedAssetTypes.splice(index, 1)
    }
  }
  // transFilterStore.recentTransAssetTypes[type.id].isChecked = isChecked;
};

const recentTransSaleTypesSelections = [
  {
    id: 0,
    name: "Market",
  },
  {
    id: 1,
    name: "Non-Arms",
  },
  {
    id: 2,
    name: "Share Sale",
  },
  {
    id: 3,
    name: "Distress",
  },
  {
    id: 4,
    name: "Business Transaction",
  },
  {
    id: 5,
    name: "Right To Purchase",
  },
  {
    id: 6,
    name: "Others",
  },
];

const saleTypeIsChecked = (type) => {
  let index = transFilterStore.selectedSaleTypes.findIndex(t => t == type)
  return (index != -1)? true: false;
}
const handleSaleTypeChange = (isChecked, type) => {
  if(isChecked) {
    transFilterStore.selectedSaleTypes.push(type)
  } else {
    let index = transFilterStore.selectedSaleTypes.findIndex(t => t == type)
    if(index != -1) {
        transFilterStore.selectedSaleTypes.splice(index, 1)
    }
  }
};

// const featureTypeMarker = "transactionMarker";
// const featureTypeCluster = "transactionCluster";

// var markerImage = new olStyle.Icon({
//   anchor: [0.5, 1],
//   crossOrigin: "anonymous",
//   scale: 0.1,
//   src: "/assets/img/home-map-marker.png",
// });
// var clusterImage = new olStyle.Circle({
//   radius: recentTransClusterRadius,
//   stroke: new olStyle.Stroke({ color: "#fff" }),
//   fill: new olStyle.Fill({ color: "#0099e6" }),
// });
// var clusterTextFill = new olStyle.Fill({ color: "#fff" });

// // Create the Recent Transaction Cluster Layer
// const recentTransLayer = new VectorLayer({
//   title: "Recent Transaction",
//   name: "recentTrans",
//   visible: false,
//   style: function (feature) {
//     var size = feature.get("features").length;
//     var style;
//     if (size == 1) {
//       feature.featureType = featureTypeMarker;
//       style = new olStyle.Style({
//         image: markerImage,
//       });
//     } else {
//       feature.featureType = featureTypeCluster;
//       style = new olStyle.Style({
//         image: clusterImage,
//         text: new olStyle.Text({
//           text: size.toString(),
//           fill: clusterTextFill,
//         }),
//       });
//     }
//     return style;
//   },
// });
// recentTransLayer.setZIndex(1);
// mapStore.map.addLayer(recentTransLayer);

const formatDate = (value, dateType) => {
  switch (value) {
    case "year":
      return format(parseISO(value), "yyyy");
    case "month":
      return format(parseISO(value), "MMM");
    default:
      return "";
  }
  // return format(parseISO(value), 'MMM yyyy')
};

const emit = defineEmits(["onApply", "onCancel", "onClearLayer"]);

const onApply = () => {
  var markers = [];
  transDisplay.value = recentTrans;
  transDisplay.value = transDisplay.value
    .filter((tran) => {
      var found = false;
      found =
        getMonth(parseISO(tran.EventDate)) >= transFilterStore.selectedMonthsLower &&
        getMonth(parseISO(tran.EventDate)) <= transFilterStore.selectedMonthsUpper &&
        tran.Price >= transFilterStore.selectedPricesLower &&
        tran.Price <= transFilterStore.selectedPricesUpper;

      if (!found) return false;

      const checkboxInputsAsset = transFilterStore.selectedAssetTypes;
      const isAllcheckedAsset = checkboxInputsAsset.every((checkbox) => checkbox.isChecked);

      var isMatch = false;
      if(!isAllcheckedAsset) {
        // Check if the item has one of the values in the checkbox input array
        isMatch = checkboxInputsAsset.includes(tran.AssetType);
      }

      if(!isMatch)
        return false
      found = found && isMatch
      
      const checkboxInputsSale = transFilterStore.selectedSaleTypes;

      const isAllcheckedSale = checkboxInputsSale.every((checkbox) => checkbox.isChecked);

      let isMatch2 = false;
      if(!isAllcheckedSale) {
        let saletype = tran.SaleType == null ? "Others" : tran.SaleType.split(" - ")[0];
        isMatch2 = checkboxInputsSale.includes(saletype);
      }

      if(!isMatch2)
        return false
      found = found && isMatch2

      return found;
    })
    .map((filteredResult, i) => {
      let marker = new Feature({
        geometry: new Point(
          fromLonLat([filteredResult.Longitude, filteredResult.Latitude])
        ),
        index: i,
      });
      markers.push(marker);
      return filteredResult;
    });

  mapStore.filteredTransactions = transDisplay.value;

  // let recentTransClusterSource = new ClusterSource({
  //   distance: recentTransClusterDistance,
  //   source: new VectorSource({ features: markers }),
  // });
  // recentTransLayer.setSource(recentTransClusterSource);

  

  // mapStore.map.addLayer(recentTransLayer);
  emit("onApply");
};
const onCancel = () => emit("onCancel");
// const clearLayer = () => emit("onClearLayer");
</script>
<style scoped>
/* ion-modal.propertyModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #777;
  --border-color: #ABC098;
}

ion-modal.ownerModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #999;
  --border-color: #DEF098
} */
</style>