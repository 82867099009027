<template>

  <div v-if="loadingStore.isLoading">Loading Posts</div>

  <div v-if="props.posts.length">

    <DynamicScroller class="scroller" :items="props.posts" :min-item-size="30">
      <template #before>
        <slot />
      </template>
      <template #after>
        <div class="notice">
          You have reached the end.
        </div>
      </template>
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          :data-active="active"
        >
        <!-- <div>{{item.content}}</div>
        <PostAction :post="item" /> -->
          <Post
            :post="item"
            :max-word="200"
            @displayUserPostLikesModal="displayUserPostLikesModal"
          >
            <PostAction :post="item" />
          </Post>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>

  <!-- <div v-if="props.posts.length">
    <ion-list>
      <Post
        v-for="post in props.posts"
        :key="post.id"
        :post="post"
        :max-word="200"
        @displayUserPostLikesModal="displayUserPostLikesModal"
      >
        <PostAction :post="post" />
      </Post>
    </ion-list>
  </div> -->
  <div v-else>
    <div>
      <div class="content">
        <p
          style="text-align: center; font-size: 20px; margin-top: 20px"
          class="no-results"
        ></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import {
  reactive,
  computed,
  onMounted,
  onUnmounted,
  ref,
  defineProps,
  defineEmits,
} from "vue";
import {
  IonListHeader,
  IonCheckbox,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonLabel,
  IonRadio,
  IonToggle,
} from "@ionic/vue";
import {
  PushNotifications,
} from "@capacitor/push-notifications";

import Post from "./post.vue";
import { useSocialStore, useLoadingStore, useMapStore } from "../../state";
import PostAction from "./post-action.vue";

const loadingStore = useLoadingStore();
const props = defineProps(["posts"]);
const emits = defineEmits(["displayUserPostLikesModal"]);

const displayUserPostLikesModal = () => {
  emits("displayUserPostLikesModal");
};

PushNotifications.removeAllDeliveredNotifications();
</script>

<style scoped>
.postlistitem {
  padding-left: 0px;
}

.commentcontent {
  font-size: 13px;
}
.timestamp {
  font-size: 10px;
  font-style: italic;
  color: green;
  float: right;
}
textarea {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  width: 80%;
  resize: none;
}

.subdiv {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}

* {
  box-sizing: border-box;
}

form {
  display: flex;
  padding: 1%;
  margin: 1%;
}

.box {
  border-radius: 25px;
}

#txtPostSearch {
  background-size: 20px;
  background-position: 4px 10px;
  background-repeat: no-repeat;
  width: 97%;
  padding: 0px 5px 0px 30px;
  font-size: 16px;
  border: 1px solid #ddd;
  margin-bottom: 2px;
  margin-left: 5px;
  display: block;
  line-height: 40px;
}

#txtPostSearch::placeholder {
  font-size: 12px;
}

.scroller {
  height: calc(100vh-38px-50px);
  /* flex: auto 1 1; */
  /* padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
  /* padding-top: 38px; */
} 

/* padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */


/* .scroller {
  flex: auto 1 1;
} */


.notice {
  padding: 24px;
  font-size: 20px;
  color: #999;
}
</style>

