import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import XYZ from 'ol/source/XYZ'
import TileWMS from 'ol/source/TileWMS'
import KML from 'ol/format/KML'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector';
import {Fill, Stroke, Style, Text} from 'ol/style';


// var style = new Style({
//   fill: new Fill({
//     color: 'rgba(255, 255, 255, 0.6)',
//   }),
//   stroke: new Stroke({
//     color: '#319FD3',
//     width: 1,
//   }),
//   text: new Text({
//     font: '12px Calibri,sans-serif',
//     fill: new Fill({
//       color: '#000',
//     }),
//     stroke: new Stroke({
//       color: '#fff',
//       width: 3,
//     }),
//   }),
// });

var indmap=function(){
  var   indlayer = new VectorLayer({

    source: new VectorSource({
      url: 'data/GV_Industrial.kml',
      format: new KML(),
    }),


  })
  indlayer.getSource().on('change', function(evt){
    const source = evt.target;
    if (source.getState() === 'ready') {
      const numFeatures = source.getFeatures().length;
      console.log("Count after change: " + numFeatures);
    }
  });
  return indlayer
};

var streetmap=function(){
    var mapbaselayer=null;
    mapbaselayer=new TileLayer({
        preload: 4,
        source: new OSM(),
        type:'base',
        baseLayer:true
    })
    return mapbaselayer;
};
var googlemap=function(){
  var mapbaselayer=null;
          mapbaselayer=new TileLayer({
              preload: Infinity,
              source: new XYZ({
                attributions: '© <a href="https://cloud.google.com/maps-platform/terms/" target="_blank">Powered By Google. </a>',
                //attributionsCollapsible: false,
                url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
              }),
              type:'base',
              baseLayer:true
          })
  return mapbaselayer;
};
var satellitemap=function(){
    var additonallayers=null;
            additonallayers=new TileLayer({
                title:'Satellite view',
                name:'GSatellite',
                visible:false,
              source: new XYZ({
                attributions: ['Powered by Esri',
                               'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                maxZoom: 23
              })
            });
    return additonallayers;
}

// var bcparcelmap = function() {
//   var parcelLayer = new TileLayer({
//     title: 'Parcel lines',
//     displayInLayerPopup: "0",
//     opacity: 0.5,
//     minZoom: 12,
//     visible: true,
//     zIndex: 5,
//     preload: Infinity,
//     source: new TileWMS({
//       url:
//         "https://geoterratone.com:/geoserver/Terradots/wms?version=1.1.0",
//       crossOrigin: null,
//       params: {
//         LAYERS:"greatervancouverparcel,vancouvertielines",
//       },
//     }),
//   });
//   return parcelLayer;
// }
var bcparcelmap=function(){
    var parcelLayer = new TileLayer({
        title: 'Parcel lines',
        displayInLayerPopup:'0',
        opacity:0.7,
        minZoom:12,
        bgcolor:'0x80BDE3',
        /*
        // Perry extent
        extent:[-13708573.09907316,
          6317899.993763422,
          -13706121.72827147,
          6319568.366776173
        ],
        */
        // Richmond area west of No2 ,  Request by David for App on 9 Mar 2021
        //extent:[-13722433.185728591,
        //          6294292.953649552,
        //        -13709974.340310227,
        //          6305043.228675206],
        source: new TileWMS({
      
          url:
            "https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows",
          crossOrigin: null,
          params: {
            LAYERS: "WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW",
            STYLES: "5898",
          }
        })
        
      });

    return parcelLayer;
}
var bookmarksmap=function(){
   var style = [
    new Style({
      stroke: new Stroke({
        color: "black",
        width: 4,
      }),
      fill: new Fill({
        color: "green",
      }),
    }),
  ];
  var bookmarkLayer=new VectorLayer({
    title:'Bookmarks',
    name:'Bookmarks',
    source: new VectorSource(),
    style : style ,
    visible:false
  
  })
  
  return bookmarkLayer
}
var mapconfig={
    x:-123.1175904,     
    y:49.28764,
    zoom:17,          
    streetmap:streetmap(),
    googlemap:googlemap(),
    satellitemap:satellitemap(),
    bcparcelmap:bcparcelmap(),
    bookmarksmap:bookmarksmap(),
    indmap:indmap(),
    

};
export default mapconfig



