
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Profile</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-item
          detail
          :style="'color: blue'"
          class="userItem"
          lines="full"
          @click="router.push({ name: 'updateProfile' })"
        >
          <ion-avatar slot="start" style="width: 80px; height: 80px">
            <img :src="authStore.currentUser.photoURL" />
          </ion-avatar>
          <ion-label>{{ authStore.currentUser.displayName }}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label>{{ authStore.currentUser.email }}</ion-label>
        </ion-item>

        <!-- <div style="display:table;margin:0 auto;">
            
            <input
              id="elemFileUpload"
              type="file"
              class="form-control"
              @change="fileUploadChange"
            >
          </div> -->
      </ion-card>

      <ion-card>
        <ion-item
          detail
          :style="'color: blue'"
          class="settingItem"
          lines="full"
          @click="router.push({ name: 'bookmarks' })"
        >
          <ion-label>Manage Bookmarks</ion-label>
        </ion-item>
        <ion-item
          lines="full" detail @click="onClickRefreshMap" :style="'color: blue'">
          <ion-label>Refresh Map</ion-label>
        </ion-item>
        <ion-item
          lines="none" detail @click="showAboutAlert" :style="'color: blue'">
          <ion-label>About</ion-label>
        </ion-item>
      </ion-card>

      <ion-card>
        <ion-item button detail="false" color="danger" @click="handleLogout()" :disabled="isLogoutClicked">
          Logout 
        </ion-item>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonAvatar,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  alertController,
  loadingController, 
  toastController, 
} from "@ionic/vue";

import { useRouter } from "vue-router";
import { useAuthStore } from "../../router/useAuthStore";
import { getAuth } from "firebase/auth";
import { useMapStore } from "../../state"
import { ref } from "vue";
import { version, name } from '../../../package.json'

const router = useRouter();
const authStore = useAuthStore();
const mapStore = useMapStore()
const auth = getAuth();

const isLogoutClicked = ref(false)
const showAboutAlert = async () => {
  const alert = await alertController.create({
    header: "About this app",
    subHeader: name.toUpperCase(),
    message: name.toUpperCase() + " Version " + version,
    buttons: ["OK"],
  });
  await alert.present();
};

const handleLogout = async () => {
  // router.replace({ name: "login" });

  const loading = await loadingController.create({
    message: 'Logging out'
  });
  
  loading.present();
    
  isLogoutClicked.value = true;
  await authStore.removeUserToken();
  await auth.signOut();
  loading.dismiss();
  window.location.replace("/login");
};


const RefreshMap = () => {
  if (mapStore.map) {
    console.log("Refresh Map");

    mapStore.map.updateSize();
    mapStore.map.renderSync();
  }
};

const onClickRefreshMap = async (event) => {
  event.stopImmediatePropagation()
  RefreshMap();
  const toast = await toastController.create({
    message: "Refreshing the map...",
    duration: 2000,
  });
  return toast.present();
};

</script>
<style scoped>
ion-item.userItem {
  --min-height: 100px;
}
</style>