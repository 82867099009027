<template>
  <ion-item>Notes</ion-item>
  <ion-item><p class="notes-trimmed">{{ ownerNotesTrimmed }}...</p></ion-item>
  <ion-item lines="none" button @click="isOpen = true" detail :style="'color: blue'">
    <ion-label><a>Show Full Notes</a></ion-label>
  </ion-item>
  <ion-modal :is-open="isOpen" @didDismiss="dismiss()">
    <ion-header>
      <ion-toolbar>
        <ion-title>Notes</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="dismiss()">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <p class="notes">{{ ownerStore.ownerNotes }}</p>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import {
  IonModal,
  IonButtons,
  IonButton,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonContent,
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
} from "@ionic/vue";

import DataTable from "datatables.net-vue3";
import DataTableBs5 from "datatables.net-bs5";
import { ref, computed } from "vue";
// import { DataTable,TableBody,TableHead, } from "@jobinsjp/vue3-datatable"
import { useOwnerStore } from "../../state";

// DataTable.use(DataTableBs5);
const ownerStore = useOwnerStore();
const isOpen = ref(false);

const ownerNotesTrimmed = computed(() => {
  let notes = ownerStore.ownerNotes.substring(0, 200);
  return notes;
})

const dismiss = () => {
  isOpen.value = false;
};
</script>
<style scoped>
.notes {
  white-space: pre-wrap;
  padding-bottom: 40px;
}

.notes-trimmed {
  white-space: pre-wrap;
  /* padding: 0, 20px, 0, 20px !important; */
}
</style>