<template>
  <ion-list>
    <ion-item>
      <ion-icon :icon="personOutline" slot="start" />
      <ion-input
        v-model="updateContact.data.FirstName1"
        placeholder="First Name"
        inputmode="text"
      ></ion-input>
      <ion-input
        v-model="updateContact.data.LastName1"
        placeholder="Last Name"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon :icon="personAddOutline" slot="start" />
      <ion-input
        v-model="updateContact.data.FirstName2"
        placeholder="First Name"
        inputmode="text"
      ></ion-input>
      <ion-input
        v-model="updateContact.data.LastName2"
        placeholder="Last Name"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon :icon="businessOutline" slot="start" />
      <ion-input
        v-model="updateContact.data.Company"
        placeholder="Company"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon :icon="briefcaseOutline" slot="start" />
      <ion-input
        v-model="updateContact.data.Telephone1"
        placeholder="Office Tel"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon :icon="phonePortraitSharp" slot="start" />
      <ion-input
        v-model="updateContact.data.Telephone3"
        placeholder="Mobile Tel"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon :icon="mailOutline" slot="start" />
      <ion-input
        v-model="updateContact.data.Email"
        placeholder="Email"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon :icon="mailOutline" slot="start" />
      <ion-input
        v-model="updateContact.data.Key3"
        placeholder="Other Email"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon :icon="locationOutline" slot="start" />
      <ion-input
        v-model="updateContact.data.Street1"
        placeholder="Street"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon slot="start" />
      <ion-input
        v-model="updateContact.data.City"
        placeholder="City"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon slot="start" />
      <ion-input
        v-model="updateContact.data.State"
        placeholder="State"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon slot="start" />
      <ion-input
        v-model="updateContact.data.Zip"
        placeholder="Zip Code"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-icon slot="start" />
      <ion-input
        v-model="updateContact.data.Country"
        placeholder="Country"
        inputmode="text"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-button fill="clear" @click="handleUpdateContact">
        Update
        <ion-spinner v-if="showLoading" slot="end"></ion-spinner>
      </ion-button>
    </ion-item>
  </ion-list>
</template>

<script setup>
import router from "@/router";
import {
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSpinner,
} from "@ionic/vue";

import BasicTableApp from "./portfoliotv.vue";
import ownerNote from "./owner-note.vue";
//for API call
import axios from "axios";
import {
  computed,
  onMounted,
  ref,
  reactive,
  defineProps,
  defineEmits,
} from "vue";
import { useMapStore, useOwnerStore } from "@/state";

import {
  // caretForwardCircle,
  // close,
  // heart,
  // trash,
  // share,
  // pin,
  // checkmarkCircle,
  // ellipseOutline,
  // search,
  homeOutline,
  mailOutline,
  locationOutline,
  briefcaseOutline,
  personOutline,
  personAddOutline,
  businessOutline,
  callOutline,
  pencilOutline,
  phonePortraitSharp,
} from "ionicons/icons";

const ownerStore = useOwnerStore();
const mapStore = useMapStore();
const props = defineProps(["contact"]);
const emit = defineEmits(["onUpdateSuccess"]);

const showLoading = ref(false);

const updateContact = reactive({
  database: props.contact.database,
  id: props.contact.id,
  data: {
    FirstName1: props.contact?.FirstName1,
    FirstName2: props.contact?.FirstName2,
    LastName1: props.contact?.LastName1,
    LastName2: props.contact?.LastName2,
    Company: props.contact?.Company,
    Email: props.contact?.Email,
    Key3: props.contact?.Key3, //Email2
    Street1: props.contact?.Street1,
    City: props.contact?.City,
    State: props.contact?.State,
    Zip: props.contact?.Zip,
    Country: props.contact?.Country,
    Telephone1: props.contact?.Telephone1, //OfficeTel
    Telephone3: props.contact?.Telephone3, //MobileTel
  },
});

const handleUpdateContact = () => {
  showLoading.value = true;
  // console.log("updateContact", updateContact);

  ownerStore.updateContact(updateContact).then((data) => {
    mapStore.getParcelInfoByPID(mapStore.PID.replaceAll("-", ""));
    showLoading.value = false;
    emit("onUpdateSuccess");
  });
};
</script>


<style scoped>
</style>
