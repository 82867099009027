<template id="sheet">
  <ion-list-header class="list-header" style="border-bottom: 1px solid grey; margin-top:-20px">
    <ion-label>{{" "}}</ion-label>
    <ion-button @click="showAddPost()"
      >Add Post</ion-button
    >
  </ion-list-header>
  <div id="sheet-modal-content" overflow-y>
    <div class="page" v-if="parcelHasPost">
      <div class="post-list">
        <post-list 
          :posts="socialStore.filteredPostsByGeoHash"
        />
      </div>
    </div>
    <div v-else class="ion-text-center">
      <ion-text v-if="mapStore.hasSelectedArea">No Posts Found for this Parcel</ion-text>
      <ion-text v-else>Select Parcel to Show Parcel Posts</ion-text>  
    </div>
    <ion-item
      class="sheet-last-item"
      lines="full"
      @click="router.push({ path: 'post/g/all' })"
    >
      <ion-text color="primary">See all Posts</ion-text>
    </ion-item>
  </div>
  <create-post :showCreate="isShowCreate" @displayPostList="displayPostList" />
</template>


<script setup>
import {
  IonText,
  IonListHeader,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { useSocialStore, useMapStore } from "@/state";
import { ref, computed } from "vue";

import PostList from "@/components/social-page/post-list.vue";
import CreatePost from "@/components/sheet-modal/create-post.vue";

const router = useRouter();
const socialStore = useSocialStore();
const mapStore = useMapStore()
const isShowCreate = ref(false);

const parcelHasPost = computed(() => socialStore.filteredPostsByGeoHash.length > 0);


const showAddPost = () => {
  isShowCreate.value = true;
};


const displayPostList = () => {
  isShowCreate.value = false;
};
// const emit = defineEmits(["toggleLandMergerMode", "cancelLandMergerMode"]);
</script>
<style scoped>
.sheet-last-item {
  margin-bottom: 51px;
}

.post-list {
  /* padding:  0 50px; */
  max-width: 800px;
  width: 100%;
  margin:0 auto;
}
.page {
  background-color: lightgray;
}
/* ion-modal.ownerModal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
} */

/* ion-modal.propertyModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #777;
  --border-color: #ABC098;
}

ion-modal.ownerModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #999;
  --border-color: #DEF098
} */
</style>