import { createRouter, createWebHistory, createMemoryHistory } from '@ionic/vue-router';

import { useAuthStore } from './useAuthStore';

import TabsPage from '@/views/TabsPage.vue'
import MapTabPage from '@/views/Tab1Page.vue'
import Map from '@/components/map/olmap2New.vue'
import Chat from '@/components/social-page/social-page.vue'
import Profile from '@/components/userProfile/profile.vue'
// import Login from '@/components/Login.vue'
import PostComment from '@/components/social-page/post-comment.vue'
import TransactionPage from '@/views/TransactionPage.vue'
import SearchPage from '@/components/search/search-page.vue'
import StreetView from "@/views/StreetViewPage.vue"

// import MapTab from "@/views/Tab1Page.vue"

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/components/Login.vue")
  },
  {
    path: '/propertyOwnerInfo',
    name: 'propertyOwnerInfo',
    component: () => import("@/views/OwnerTabView.vue")
  },
  {
    path: '/owner/search',
    name: 'owner-search',
    component: () => import("@/views/Tab4Page.vue"),
    props: {backButton:true, doSearch: true}
  },
  {
    path: '/property/search',
    name: 'property-search',
    component: () => import("@/views/Tab4Page.vue"),
    props: {backButton:true, doSearch: true}
  },
  {
    path: '/property',
    name: 'property',
    component: () => import("@/views/PropertyTabView.vue")
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: TransactionPage
  },
 
  {
    path: '/streetview',
    name: 'streetview',
    component: StreetView
  },
  
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/map'
      }, {
        path: 'map',
        name: 'map', 
        component: () => import("@/components/map/olmap2New.vue"),
        // props: route => ({ query: route.query.q})
      }, {
        path: 'map/search',
        name: 'map-search', 
        component: () => import("@/components/map/olmap2New.vue"),
        props: {doSearch: true}
        // props: route => ({ query: route.query.q})
      // }, {
      //   path: 'post',
      //   name: 'post-group', 
      //   component: () => import("@/views/TabPostGroupList.vue")
      },{
        path: 'post/g/:postGroupId',
        name: 'post-by-group', 
        component: () => import("@/views/TabPostList.vue")
      }, {
        path: 'post/:postId/comments',
        name: 'post-comment',
        component: () => import("@/views/CommentTabView.vue")
      },{
        path: 'profile',
        name: 'profile', 
        component: () => import("@/views/Tab3Page.vue"),
      }, {
        path: 'profile/update',
        name: 'updateProfile',
        component: () => import("@/views/UpdateProfileTabView.vue")
      }, {
        path: 'profile/bookmarks',
        name: 'bookmarks',
        component: () => import("@/views/ManageBookmarksTabView.vue")
      }, {
        path: 'search',
        name: 'search',
        component: () => import("@/views/Tab4Page.vue"),
        props: {backButton:false}
      },
      {
        path: 'bca',
        name: 'bca',
        component: () => import("@/components/bca/bca-iframe.vue")
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  console.log("beforeEach isAuthenticated", authStore.isAuthenticated)
  if (
    // make sure the user is authenticated
    !authStore.isAuthenticated &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'login'
  ) {
    // redirect the user to the login page
    return { name: 'login' }
  }
})


export default router
