<template>
    <div 
      class="transaction-content"
      v-for="transaction in mapStore.transactions" 
      :key="transaction.PIDCode"
    >
      <div>
        <table>
          <tr>
            <td class="detail-label">
              Date
            </td>
            <td class="detail-value">
              {{ getDate(transaction.EventDate) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Sale Type
            </td>
            <td class="detail-value">
              {{ transaction.SaleType }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Price
            </td>
            <td class="detail-value">
              {{ formatPrice(transaction.Price) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Capitalization
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.CapitalizationRate * 100, 100, "", "%") }}
            </td>
          </tr>
        </table>

        <div class="trans-subheading">
          General Information
        </div>
        <table>
          <tr>
            <td class="detail-label">
              PID(s)
            </td>
            <td class="detail-value">
              {{ transaction.PIDCode }}<span v-if="transaction.PIDCodes!=null">, {{ transaction.PIDCodes }}</span>
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              <span v-if="transaction.Address.split('\n').length==1">Address</span>
              <span v-else>Addresses</span>
            </td>
            <td class="detail-value">
              <ul style="list-style-type:none;">
                <li 
                  v-for="address in transaction.Address.split('\n')" 
                  :key="address"
                >
                  {{ address }}
                </li>
              </ul>  
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Municipality
            </td>
            <td class="detail-value">
              {{ transaction.Municipality }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Asset Type
            </td>
            <td class="detail-value">
              {{ assetTypes[transaction.AssetType] }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Construction Year
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.ConstructionYear) }}
            </td>
          </tr>
        </table>

        <div class="trans-subheading">
          Property Size
        </div>
        <table v-if="transaction.AssetType=='APT'">
          <tr>
            <td class="detail-label">
              Site Size
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.lotSize, 0, "", transaction.lotSizeUnit) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              # of Units
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.TotalUnits, 0) }}
            </td>
          </tr>
          <tr v-if="transaction.TotalUnits != 0">
            <td class="detail-label" />
            <td class="detail-value">
              {{ formatPrice(transaction.Price / transaction.TotalUnits, "", " / unit") }}
            </td>
          </tr>
        </table>
        <table v-else-if="transaction.AssetType=='ICI' || transaction.AssetType=='RLN'">
          <tr>
            <td class="detail-label">
              Site Size
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.lotSize, 0, "", transaction.lotSizeUnit) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              FSR
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.buildableArea / lotSize, 100) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Buildable Area
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.buildableArea, 0, "", " sq.ft") }}
            </td>
          </tr>
          <tr v-if="transaction.PricePerSqFtBuildable != 0">
            <td class="detail-label" />
            <td class="detail-value">
              {{ formatPrice(transaction.PricePerSqFtBuildable, "", " / bsf") }}
            </td>
          </tr>
        </table>
        <table v-else-if="transaction.AssetType=='IND' || transaction.AssetType=='OFF' || transaction.AssetType=='RET'">
          <tr>
            <td class="detail-label">
              Site Size
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.lotSize, 0, "", transaction.lotSizeUnit) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Gross Leasable Area
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.BuildingSize, 0, "", " sq.ft") }}
            </td>
          </tr>
          <tr v-if="transaction.BuildingSize != 0">
            <td class="detail-label" />
            <td class="detail-value">
              {{ formatPrice(transaction.BuildingSize / transaction.BuildingSize, "", " / sq.ft") }}
            </td>
          </tr>
        </table>
        <table v-else>
          <tr>
            <td class="detail-label">
              Site Size
            </td>
            <td class="detail-value">
              {{ formatNumber(transaction.lotSize, 0, "", transaction.lotSizeUnit) }}
            </td>
          </tr>
        </table>

        <div class="trans-subheading">
          Assessment Values {{ formatNumber(transaction.AssessmentYear, -1, "(Year ", ")") }}
        </div>
        <table>
          <tr>
            <td class="detail-label">
              Land
            </td>
            <td class="detail-value assessment-value">
              {{ formatPrice(transaction.LandAssessment) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Building
            </td>
            <td class="detail-value assessment-value">
              {{ formatPrice(transaction.BuildingAssessment) }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Total
            </td>
            <td class="detail-value assessment-value">
              {{ formatPrice(transaction.LandAssessment + transaction.BuildingAssessment) }}
            </td>
          </tr>
        </table>
      
        <div class="trans-subheading">
          Vendor Information
        </div>
        <table>
          <tr>
            <td class="detail-label">
              Name
            </td>
            <td class="detail-value">
              {{ transaction.VendorName }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Signing Officer
            </td>
            <td class="detail-value">
              {{ transaction.VendorSigningOfficer }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Address
            </td>
            <td class="detail-value">
              {{ transaction.VendorAddress }}
            </td>
          </tr>
        </table>

        <div class="trans-subheading">
          Purchaser Information
        </div>
        <table>
          <tr>
            <td class="detail-label">
              Name
            </td>
            <td class="detail-value">
              {{ transaction.PurchaserName }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Signing Officer
            </td>
            <td class="detail-value">
              {{ transaction.PurchaserSigningOfficer }}
            </td>
          </tr>
          <tr>
            <td class="detail-label">
              Address
            </td>
            <td class="detail-value">
              {{ transaction.PurchaserAddress }}
            </td>
          </tr>
        </table>

        <div class="trans-subheading">
          Broker Information
        </div>
        <tr>
          <td class="detail-label">
            Brokers
          </td>
          <td class="detail-value">
            {{ transaction.Brokers }}
          </td>
        </tr>
      
        <div class="trans-subheading">
          Land Use Details
        </div>
        <div class="detail-value ion-padding">
          {{ transaction.LandUseDetails }}
        </div>

        <div class="trans-subheading">
          General Remarks
        </div>
        <div class="detail-value ion-padding">
          {{ transaction.GeneralRemarks }}
        </div>
      </div>
    </div>

    <!-- Multiple Transactions Selection Page -->
    <!-- <div v-else>
      <p>Transactions Count: {{transactions.length}}</p>
      <p style="color:red" v-show="transactions.length > transactionsCount">
        Only the first {{transactionsCount}} transactions are shown below.
      </p>
      <div v-for="i in transactionsCount" v-bind:key="i" v-on:click="selectTransaction(i-1)">
        <strong>
          Transaction Date: {{getDate(transactions[i-1].EventDate)}}<br>
          {{transactions[i-1].Address.split("\n")[0]}}<br>
        </strong>
        Asset Type: {{assetTypes[transactions[i-1].AssetType]}}<br>
        Sale Type: {{transactions[i-1].SaleType}}
      </div>
    </div> -->
</template>

<script setup>
import { 
  // IonPage, 
  // IonContent,
  IonListHeader,
  // IonToolbar,
  // IonButtons,
  IonItem,
  IonList, 
  IonLabel, 
  // IonHeader,
  // IonTitle,
  // IonBackButton 
} from '@ionic/vue'
import { computed, onMounted } from 'vue';
import { useMapStore } from '../../state';
import { useRouter } from 'vue-router';

const router = useRouter()
const mapStore = useMapStore()

var dataIndex = -1
var carouselIndex = 0
const assetTypes = {
      "HOT": "Hotel",
      "ICI": "ICI Land",
      "IND": "Industrial",
      "APT": "Multi-Family",
      "OFF": "Office",
      "RET": "Retail",
      "RLN": "Residential Land"
      //"RLT": "Residential Lots"
    }

// const addresses = computed(() => mapStore.transactions.Address.split("\n"))
onMounted(() => {
    console.log("mapStore.transactions", mapStore.transactions)
    if(mapStore.transactionsCount == 1) {
        // setTransData(0);
    } else {
        dataIndex = -1
    }
    carouselIndex = 0
    
})

const goBack = () => {
    // if (this.dataIndex == -1 || this.transactions.length == 1) {
        router.back();
    // } else {
    //     this.dataIndex = -1;
    // }
}

const getDate = (dt) => {
    if (dt == null) {
    return "-";
    }
    return dt.split("T")[0];
}

const formatPrice = (price, prefix="", suffix="", showPosSign=false) => {
    if (price == null || price==Infinity) return "-";
    var sign = price < 0 ? "-" : (showPosSign ? "+" : "");
    return prefix + sign + "$" + Math.abs(Math.round(price)).toLocaleString("en-CA") + suffix;
}

const formatNumber = (n, digits=-1, prefix="", suffix="", showZero=false, showSign=false) => {
    if (n==null || (n==0 && !showZero) || n==Infinity) return "-";
    if (digits > 0) n = Math.round(n * digits) / digits;
    var nStr = digits == -1 ? n : n.toLocaleString("en-CA");
    return prefix + ((showSign && n > 0) ? "+" : "") + nStr + suffix;
}

</script>

<style>
  .trans-subheading {
    text-align: left;
    background-color: #ccc;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 3px;
    padding-left: 5px;
    font-weight: bold;
  }
  .detail-label {
    color: #070770;
    font-weight: bold;
    padding-top: 3px;
    padding-left: 5px;
    vertical-align: top;
    width: 170px;
  }
  .detail-value {
    padding-top:3px;
  }
  .assessment-value {
    width: 90px;
    text-align: right;
  }
  .transaction-content{
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    margin-bottom: env(safe-area-inset-bottom)+5;
  }
</style>

