import { defineStore } from 'pinia'

export const useTransactionFilterStore = defineStore('transactionFilter', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      mostRecentTransYear: 2021,
      recentTrans: [],
      // transDisplay: [],
      recentTransCache: [],
      selectedYear: 2021,
      selectedMonthsLower: 0,
      selectedMonthsUpper: 2,
      selectedPricesLower:0,
      selectedPricesUpper:20000000,

      selectedAssetTypes: ["HOT","ICI","IND","APT","OFF","RET","RLN"],
      selectedSaleTypes: ["Market", "Non-Arms", "Share Sale", "Distress", "Business Transaction", "Right To Purchase", "Others"],
      // mergedPIDs: [],
      // lastVertices: [],
      // isMultipleSelectionOn: false
    }
  },

  
  getters: {

  }, 
  actions: {

  }
})