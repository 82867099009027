

import { db, db2 } from "../firebase";
import { 
    collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
    onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit 
} from "firebase/firestore";
import _  from "lodash"
import axios from 'axios';

import { defineStore } from 'pinia'
import { useAuthStore } from '../router/useAuthStore';
import { isPlatform } from '@ionic/vue';


export const usePostGroupStore = defineStore('postGroup', {
    // arrow function recommended for full type inference
    state: () => {
      return {
        filterGroupByCreator: false,
        allPostGroup: {id: "all", name: "All Posts"}, 
        postGroupId: "",
        postGroups: []
      }
    },
    getters: {
        postGroupsSortByTimeDesc: (state) => _.orderBy(state.postGroups, ['name'], ['asc']), 
        getPostGroupById: (state) => (groupId) => {
            const authStore = useAuthStore();

            if(groupId == 'all') {
                return state.allPostGroup;
            } else {
                let result = state.postGroups.filter((group) =>  group.id == groupId).map(group => 
                    ({ ...group, editable: group.creatorId === authStore.currentUser.uid })
                )[0];

                return result;
            }
        }, 
        filterPostGroupName: (state) => {
            const authStore = useAuthStore()

            let regex = new RegExp(state.strPostSearch, 'gi')
            let filteredPostGroups = []

            filteredPostGroups = state.postGroups.filter((group) => 
                state.filterGroupByCreator? group.creatorId == authStore.currentUser.uid : group
            )            
            .filter((group) => 
                group.name.match(regex)
            );
            return _.orderBy(filteredPostGroups, ['createdOn'], ['desc']);
        },
        // isCurrentUserGroupCreator: (state) => ()
    }, 
    actions: {
        async fetchPostGroups() {
            const querySnapshot = await getDocs(query(collection(db, "postGroups")));
            this.postGroups = []
            querySnapshot.forEach((doc) => {
                this.postGroups.push({id:doc.id, ...doc.data()})
            });
        },

        async addPostGroup(group) {     
            const authStore = useAuthStore()

            group.creator = authStore.currentUser.displayName
            group.creatorId = authStore.currentUser.uid
            group.createdOn = Timestamp.now()

            if(!this.postGroups.find(statePostGroup => statePostGroup.name == group.name)) {
                const docRef = await addDoc(collection(db, "postGroups"), group);
                this.postGroups.push({id:docRef.id, ...group})
            }
        },
        async updatePostGroup(group) {
            const authStore = useAuthStore()

            let index = this.postGroups.findIndex(statePostGroup => statePostGroup.id == group.id && statePostGroup.creatorId == authStore.currentUser.uid)
            
            if(index != -1) {
                await updateDoc(doc(db, "postGroups", group.id), { name: group.name, updatedOn: Timestamp.now()}) 
                this.postGroups[index].name = group.name;
            }
            
        }, 
        // disable move and delete function, pending for post/ group user role management implemenetation 
        // async movePostsToGroup(bookmarks, bookmarkGroup) {
        //     const authStore = useAuthStore()
                        
        //     for(let bookmark of bookmarks) {
        //         let index = this.bookmarks.findIndex(stateBookmark => stateBookmark.id == bookmark.id)
        //         if(index != -1) {
        //             await updateDoc(doc(db, "users", authStore.currentUser.uid, "bookmarks", bookmark.id), { groupId: bookmarkGroup.id }) 

        //             this.bookmarks[index].groupId = bookmarkGroup.id;
        //         }
        //     }
            
        // },
        // async deletePostGroup(group_id) {
        //     const authStore = useAuthStore()
            
        //     let index = this.bookmarkGroups.findIndex(stateBookmarkGroup => stateBookmarkGroup.id == group_id)
        //     if(index != -1) {
        //         const docRef = doc(db, "users", authStore.currentUser.uid, "bookmarkGroups", this.bookmarkGroups[index].id)
        //         await deleteDoc(docRef);
        //         this.bookmarkGroups.splice(index, 1)
        //     }
        // },
    }
})