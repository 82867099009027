<template>
  <!-- <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Post Groups</ion-title>
      </ion-toolbar>
    </ion-header> -->
  <!-- <ion-content class="ion-padding"> -->
  <!-- <ion-list v-if="isEditMode">
    <ion-item
      lines="full"
      v-for="postGroup in postGroupStore.postGroupsSortByTimeDesc"
      :key="postGroup.id"
      @click="() => handleMoveAction(postGroup)"
    >
      <ion-label style="font-size: 15px">{{ postGroup.name }}</ion-label>
    </ion-item>
    <ion-item button lines="full" @click="handleCreatePostGroup">
      <ion-label style="font-size: 15px">Create Post Group</ion-label>
    </ion-item>
  </ion-list> -->
  <!-- <ion-list v-if="!isEditMode"> -->
  <ion-list>
    <ion-item
      v-if="props.isShowAllPosts"
      lines="full"
      @click="() => groupItemOnClick(defaultPostGroup)"
      >All Posts</ion-item
    >
    <ion-item
      lines="full"
      v-for="postGroup in postGroupStore.postGroupsSortByTimeDesc"
      :key="postGroup.id"
      @click="() => groupItemOnClick(postGroup)"
    >
      <ion-label style="font-size: 15px">{{ postGroup.name }}</ion-label>
    </ion-item>
    <ion-item button lines="full" @click="handleCreatePostGroup">
      <ion-label style="font-size: 15px">Create Post Group</ion-label>
    </ion-item>
  </ion-list>
  <!-- </ion-content> -->
  <!-- </ion-page> -->
</template>


<script setup>
import {
  IonListHeader,
  IonCheckbox,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonBackButton,
  IonIcon,
  IonModal,
  IonRow,
  alertController,
} from "@ionic/vue";

import { trashOutline, caretDownOutline, pencilOutline } from "ionicons/icons";
import { ref, computed, defineEmits, defineProps } from "vue";
// import { useRouter } from "vue-router";
import { onMounted } from "vue-demi";
import { usePostGroupStore } from "@/state/postGroupStore";

import _ from "lodash";

// const router = useRouter();

const postGroupStore = usePostGroupStore();

const isPostGroupNameEmpty = ref(false);

const isEditMode = ref(false);
const selectedList = ref([]);
const titleSelect = computed(() =>
  selectedList.value.length > 0
    ? `${selectedList.value.length} Selected`
    : postGroupSelect.value.name
);

// const isPostGroupModalOpen = ref(false);
const defaultPostGroup = postGroupStore.allPostGroup;
const postGroupSelect = ref(defaultPostGroup);

const props = defineProps({
  isShowAllPosts: { type: Boolean, required: false, default: true },
});

const emit = defineEmits(["handleGroupItemClick"]);

// onMounted(() => {
//   socialStore.fetchPostGroups();
// });

// const onBookmarkListChange = (e) => {
//   postGroupSelect.value = e.detail.value;
// };

// const handleBookmarkSelect = async (e, bookmark) => {
//   if (e.detail.checked) {
//     selectedList.value.push(bookmark);
//   } else {
//     selectedList.value = selectedList.value.filter(
//       (bm) => bm.id != bookmark.id
//     );
//   }
// };

// // const handleMoveButtonClick = () => {
// //   isBookmarkGroupModalOpen.value = true;
// // };

const handleCreatePostGroup = async () => {
  const emptyPostNameAlert = await alertController.create({
    header: "Cannot create post group with empty name",
    subHeader: "Please enter new post group name",
    cssClass: "danger",
    buttons: [
      {
        text: "OK",
      },
    ],
  });

  const alert = await alertController.create({
    header: "Enter new post group name",
    buttons: [
      {
        text: "Create",
        handler: async (value) => {
          if (!_.isEmpty(value[0])) {
            // isPostGroupNameEmpty.value = false;
            postGroupStore.addPostGroup({ name: value[0] });
          } else {
            // isPostGroupNameEmpty.value = true;
            await emptyPostNameAlert.present();
          }
        },
      },
      {
        text: "Cancel",
        role: "cancel",
      },
    ],
    inputs: [
      {
        placeholder: "Group name",
      },
    ],
  });

  await alert.present();
};

// const handleMoveAction = (bookmarkGroup) => {
//   socialStore.movePostsToGroup(selectedList.value, bookmarkGroup);
//   selectedList.value = [];
//   isEditMode.value = false;
// //   isBookmarkGroupModalOpen.value = false;
// };

// const handleBookmarkGroupNameUpdate = async () => {
//   console.log(titleSelect.value);
//   if (selectedList.value.length === 0 && titleSelect.value != "All Bookmarks") {
//     const alert = await alertController.create({
//       header: "Update bookmark group name",
//       buttons: [
//         {
//           text: "Update",
//           handler: (value) => {
//             socialStore.updateBookmarkGroup({
//               id: postGroupSelect.value.id,
//               name: value[0],
//             });
//           },
//         },
//         {
//           text: "Cancel",
//           role: "cancel",
//           cssClass: "alert-button-cancel",
//         },
//       ],
//       inputs: [
//         {
//           placeholder: "Group name",
//           value: postGroupSelect.value.name,
//         },
//       ],
//     });

//     await alert.present();
//   }
// };

const groupItemOnClick = (postGroup) => {
  emit("handleGroupItemClick", postGroup);
};

// const viewPostsByGroup = (postGroup) => {
//   // console.log(bookmarkGroup);

//   router.push({ name: "post-by-group", params: { postGroupId: postGroup.id } });

//   //   isBookmarkGroupModalOpen.value = false;
// };

// const handleDeleteBookmarks = async (parcel_ids) => {
//   const alert = await alertController.create({
//     header: `Do you really want to delete ${selectedList.value.length} selected bookmarks?`,
//     cssClass: "custom-alert",
//     buttons: [
//       {
//         text: "No",
//         role: "cancel",
//         cssClass: "alert-button-cancel",
//       },
//       {
//         text: "Yes",
//         role: "destructive",
//         cssClass: "alert-button-delete",
//         handler: () => {
//           socialStore.deleteBookmarks(selectedList.value);
//           selectedList.value = [];
//         },
//       },
//     ],
//   });

//   await alert.present();
// };

// const handleDeleteBookmark = async (parcel_id) => {
//   //statusSpan.innerText = 'deleting...';

//   const alert = await alertController.create({
//     header: "Do you really want to delete the bookmark ?",
//     cssClass: "custom-alert",
//     buttons: [
//       {
//         text: "No",
//         role: "cancel",
//         cssClass: "alert-button-cancel",
//       },
//       {
//         text: "Yes",
//         role: "destructive",
//         cssClass: "alert-button-delete",
//         handler: () => bookmarkStore.deleteBookmark(parcel_id),
//       },
//     ],
//   });

//   await alert.present();
// };

// const handleDeleteBookmarkGroup = async (groupId) => {
//   const alert = await alertController.create({
//     header: "Do you really want to delete the bookmark group?",
//     cssClass: "custom-class",
//     buttons: [
//       {
//         text: "No",
//         role: "cancel",
//         cssClass: "alert-button-cancel",
//       },
//       {
//         text: "Yes",
//         cssClass: "alert-button-delete",
//         handler: () => {
//           bookmarkStore.deleteBookmarkGroup(groupId);
//           viewBookmarksByGroup(defaultBookmarkGroup);
//           isEditMode.value = false;
//         },
//       },
//     ],
//   });

//   await alert.present();
// };
</script>

<style scoped>
.btnDeleteGroup {
  margin-top: 50%;
}

button.alert-button-delete {
  background-color: var(--ion-color-success);
  color: var(--ion-color-danger);
}
</style>
