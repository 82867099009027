// import fb from "../firebase"
// Import the EventBus we just created.
// import EventBus from '../config/event-bus';

import { currentUser, db, db2 } from "../firebase";
import { 
    collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
    onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit 
} from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import _, { map } from "lodash"
import axios from 'axios';
// handle page reload

import { defineStore } from 'pinia'
import { useAuthStore } from '../router/useAuthStore';
import { isPlatform } from '@ionic/vue';

var StartTime = new Date().getTime()

export const globalStore = defineStore('global', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      counter: 0,
      name: 'Eduardo',
      isAdmin: true,
    }
  },
})
const TRANSACTIONSLIMIT = 50
const proxyurl1 = "https://index.laurel.workers.dev/?"

export const useMapStore = defineStore('map', {
    state: () => {
      return {
        geoHash: "",
        map: null,
        PID: "", 
        strSearch: "", 
        selectedArea: {},
        transactions:[],
        filteredTransactions:[],
        lonlat: [],
        isSheetModalOpen: false,
        selectedParcel: {},
        selectedParcel_id: "",
        selectedCentre:{}, 
        selectedAddress:""
      }
    },
    getters: {
        fabVerticalPos:(state) => state.isSheetModalOpen && isPlatform("mobile")?"center":"bottom",
        hasSelectedArea: (state) => !_.isEmpty(state.selectedArea), 
        transactionsCount: (state) => state.transactions.length > TRANSACTIONSLIMIT? TRANSACTIONSLIMIT: state.transactions.length,
    }, 
    actions: {
        onSheetModelClose() {
            this.isSheetModalOpen = false
            this.selectedArea = {}
        },
        async getParcelInfoByPID(iPID) {
            const ownerStore = useOwnerStore()
            const propertyStore = usePropertyStore()
            console.log(iPID, iPID)
            // var aOwner = {};
            // var aProperty = {};
            this.PID =iPID.substr(0, 3) + "-" + iPID.substr(3, 3) + "-" + iPID.substr(6)
            // var info = "";
            // var dbURL ="https://2caz1ubxn0.execute-api.us-west-2.amazonaws.com/prod/parcel/byID/" + this.PID
            // var dbURL ="https://gf66nogve2.execute-api.us-west-2.amazonaws.com/sandbox/parcel/" + this.PID

            var dbURL = `${process.env.VUE_APP_AWS_PARCEL_API_URL}/${this.PID}`

            // https://gf66nogve2.execute-api.us-west-2.amazonaws.com/sandbox/parcel/byID/015-508-731
            // let that=this
            await axios.get(proxyurl1 + dbURL).then((response) => {
              console.log("in GetParcelInfoByPID", response);
              if (response.data.statusCode == "200" && response.data.body) {
                  let ownerObj = {}
                _.forEach(response.data.body.Info, (value, key) => {
                    // console.log("Info",key, value )
                  if (!value || value == "null") {
                    value = "";
                  }
                  ownerObj[key] = value;
                });


                this.owner = ownerObj
                // this.property = response.data.body
                let propertyObj = {}
                _.forEach(response.data.body, (value, key) => {
                    // console.log("body",key, value )
                  if (key != "ParcelPolygon" && key != "Info") {
                    if (!value || value == "null") {
                      value = "";
                    }
                    propertyObj[key] = value;
                  }
                });
                
                // this.property["pid"] = this.PID;
                this.property = {pid: this.PID, ...propertyObj}
              } else {
                this.property=null
                this.owner=null
              }

              propertyStore.property = this.property;

              ownerStore.owner = this.owner;
            })
        }, getRecentTransactions(clickedFeature) {
            this.transactions = []
            var innerFeatures = clickedFeature.get('features')
            console.log('innerFeatures', innerFeatures)
            for (let innerFeature of innerFeatures) {
              let index = innerFeature.get('index')
              this.transactions.push(this.filteredTransactions[index])
            }
        }
    }
})

const STATUS_LOADING = "LOADING";
const STATUS_FINISH = "FINISH"
const STATUS_ERROR = "ERROR"
const STATUS_IDLE = "IDLE"
export const useLoadingStore = defineStore('loading', {
    state: () => {
        return {
            loadingStatus: STATUS_IDLE,
            loadingMessage: ""
        }
    },
    getters: {
        isLoading: (state) => state.loadingStatus === STATUS_LOADING,
        isLoadingFinish: (state) => state.loadingStatus === STATUS_FINISH,
        isLoadingError: (state) => state.loadingStatus === STATUS_ERROR,
        isIdle: (state) => state.loadingStatus === STATUS_IDLE,
    },
    actions: {
        onLoading(msg) {
            this.message = msg
            this.loadingStatus = STATUS_LOADING
        },
        onLoadingFinish(msg) {
            this.message = msg
            this.loadingStatus = STATUS_FINISH
        },
        onLoadingError(err) {
            this.message = err
            this.loadingStatus = STATUS_ERROR
        },
    }
})
export const usePropertyStore = defineStore('property', {
    state: () => {
        return {
            property: {}
        }
      },
      getters: {
        AssessmentLand: (state) => {
            return state.property?.CurrAssessLand?.replace(/.(?=(?:.{3})+$)/g,"$&,")
        },
        AssessmentBuilding: (state) => state.property?.CurrAssessBuilding?.replace(/.(?=(?:.{3})+$)/g, "$&,"),
        displayAddressForModal: (state) => (isToBeConfirmed) => (isToBeConfirmed)? state.property?.Address + " (To be Confirmed)": state.property?.Address
      }, 
      actions: {
        
      }
})

export const useOwnerStore = defineStore('owner', {
    state: () => {
        return {
            owner: {},
            ownerNote: {}, 
            portfolio: []
        }
      },
      getters: {
          getPortfolioTableData: (state) => {
            return state.portfolio.map(({LocationOriginal, CurrAssessTotal, LO}) => {
                if(CurrAssessTotal >= 1000000) {
                    CurrAssessTotal = Math.round(CurrAssessTotal/100000) / 10 + 'M';
                }
                return ([LocationOriginal, CurrAssessTotal, LO])})
          },
          displayOwnerFullName: (state) => state.owner?.FirstName1 + " " + state.owner?.LastName1,
      }, 
      actions: {
        updateContact: async (contact) => {
            const mapStore = useMapStore()

            // scene: create contact info from property info page, need to link with property 
            const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

            var dbURL = `${process.env.VUE_APP_CLOUD_FUNCTION_URL}/contacts`;
            
            // console.log(contact)
            return await axios({
                method: 'put',
                url: proxyurl1 + dbURL, 
                data: contact
            })
        }
        
      }
})

export const useSearchStore = defineStore('search', {
    state: () => {
        return {
            strSearch: "",
            contactSearchResult: [],
            titleSearchResult: [],
            companySearchResult: [],
            // items4: [],
            // for using pdf.js pre-build web viewer
            name: "Test_PDF.pdf", //change which pdf file loads
            path: "lib/web/viewer.html", //path of the PDF.js viewer.html

            showDialog: false,
            showContacts:true,
            showCompanies:true,
            showTitles:true,
        }
      },
      getters: {
        contactSearchString: (state) => state.strSearch,
        titleSearchString: (state) => state.strSearch.toUpperCase(),
        companySearchString: (state) => state.strSearch.toUpperCase(),
      }, 
      actions: {
        async searchResult () {
            if(this.showContacts) this.contactSearch()
            if(this.showCompanies) this.companySearch()
            if(this.showTitles) this.titleSearch()
        },
        async contactSearch() {
            // if (this.strSearch) {
            var contactArray = [];
            const search_criteria = "&criteria=name,company,property-address";
            //const search_criteria="&criteria=name,company,telephone,contact-address,property-address,city,zip"
            //https://hv3ziiajak.execute-api.us-west-2.amazonaws.com/Beta/contact/Amy C
            
            var dbURL = `${process.env.VUE_APP_CLOUD_FUNCTION_URL}/contacts/linkproperty/search?text=${this.contactSearchString + search_criteria}`;

            var contactlist = [];
    
            const response = await axios.get(dbURL);
            if (response.status == 200) {
                contactlist = response.data;
                contactlist.forEach(function(contact) {
                    contactArray.push(contact);
                });
            }
            
            this.contactSearchResult = contactArray;
        },
        async titleSearch(){
            this.titleSearchResult = [];
            console.log("titleSearch", this.titleSearchString);
            var strlength = this.titleSearchString.length;
            var strFrontCode = this.titleSearchString.slice(0, strlength - 1);
            var strEndCode = this.titleSearchString.slice(
                strlength - 1,
                this.titleSearchString.length
            );

            var startcode = this.titleSearchString;
            var endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
            var titleArray = [];
            console.log("titleSearch2", startcode, endcode)

            const querySnapshot = await getDocs(query(
                collection(db2, "titlesearch"), 
                where("filename", ">=", startcode), 
                where("filename", "<", endcode), 
                orderBy("filename"),
                limit(10)));

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                this.titleSearchResult.push({id:doc.id, ...doc.data()})
            });
        },
        async companySearch() {
            this.companySearchResult = [];
            console.log("companySearch", this.companySearchString);
            var strlength = this.companySearchString.length;
            var strFrontCode = this.companySearchString.slice(0, strlength - 1);
            var strEndCode = this.companySearchString.slice(
              strlength - 1,
              this.strSearch.length
            );
    
            var startcode = this.companySearchString;
            var endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
            var companyArray = [];

            console.log("companySearch2", startcode, endcode)
            const querySnapshot = await getDocs(query(
                collection(db2, "corpsearch"),               
                where("filename", ">=", startcode), 
                where("filename", "<", endcode), 
                orderBy("filename"),  
                limit(10)));

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                this.companySearchResult.push({id:doc.id, ...doc.data()})
            });
        }
      }
})



export const useSocialStore = defineStore('social', {
    // arrow function recommended for full type inference
    state: () => {
      return {
        isPostFetched: false, 
        posts: [],
        newPostCount: 0, 
        comments: [],
        hiddenPosts: [],
        likedPosts: [], //TODO: sets()
        likedComments: [], //TODO: sets()
        likes:[],
        commentLikes:[],
        selectedPost: {},
        strPostSearch: "",
        filterPostPid: "",
        filterPostAddress: "",
        filterByCreator: false,
        filterGeoHash: "",
        filterPolygonCoordinates: [], // for old posts do not have geoHash

        filterGroupByCreator: false,
        postGroupId: "",
        postGroups: []
      }
    },
    getters: {
        getPostByPostId: (state) => (postId) => {
            let post = state.posts.filter((post) => post.id === postId)
            if(post.length > 0) {
                return post
            } else {
                return state.fetchPostbyPostId(postId)
            }
        },
        canPostBeDeleted: (state) => (postId) => {
            const authStore = useAuthStore()
            const postToDelete = state.posts.filter(post => post.id === postId && post.userId === authStore.currentUser.uid)
            return postToDelete.length > 0;

        }, 
        filteredPosts: (state) => {
            const authStore = useAuthStore()
            const loadingStore = useLoadingStore();

            loadingStore.onLoading('Loading Posts');
            let filteredPosts = []

            const postMatch = (post) => {
                let regex = new RegExp(state.strPostSearch, 'gi')

                // console.log('postMatch', post);
                
                return (
                    post.content.match(regex) || 
                    post.propinfo?.Address?.match(regex) ||
                    post.userName?.match(regex) ||
                    post.title?.match(regex) 
                );
            }

            filteredPosts = state.posts
            .filter((post) => (state.postGroupId == "" ||  state.postGroupId == "all") ? true: post.groupId === state.postGroupId)
            .filter((post) => {
                if (!state.filterByCreator) {
                    return !post.deleted;
                } else {
                    return (
                        post.userId == authStore.currentUser.uid &&
                        !post.deleted
                    );
                }
            }).filter((post) => {
                if(state.strPostSearch)
                    return postMatch(post)
                else 
                    return true
            });

            loadingStore.onLoadingFinish();
            
            return _.orderBy(filteredPosts, ['createdOn'], ['desc']);
        },
        filteredPostsByPid: (state) => {
            return state.posts.filter((post) => {
                // if (Object.prototype.hasOwnProperty.call(post, "propinfo")) {
                if(post.propinfo) {
                    return post.propinfo.pid == state.filterPostPid && !post.deleted
                } else {
                    return false && !post.deleted
                }
            });
        },
        filteredPostsByGeoHash: (state) => {
             const postsByGeo = state.posts.filter((post) => {
                if(post.propinfo) {
                    if(post.propinfo.geoHash) {
                        return post.propinfo.geoHash == state.filterGeoHash && !post.deleted
                    } 
                    else if(post.centre && state.filterPolygonCoordinates) { // for posts without geoHash
                        const inside = (point, vs) => {
                            // ray-casting algorithm based on
                            // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html
                            
                            var x = point[0], y = point[1];
                            
                            var inside = false;
                            for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
                                var xi = vs[i][0], yi = vs[i][1];
                                var xj = vs[j][0], yj = vs[j][1];
                                
                                var intersect = ((yi > y) != (yj > y))
                                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                                if (intersect) inside = !inside;
                            }
                            
                            return inside;
                        }

                        return inside(post.centre, state.filterPolygonCoordinates) && !post.deleted
                    } 
                            
                } else {
                    return false && !post.deleted
                }
            });

            return _.orderBy(postsByGeo, ['createdOn'], ['desc']); 
        },
        findLikedPostsIndex: (state) => (postId) => {
            return state.likedPosts.findIndex(likedPost => likedPost.postId == postId)
        },
        checkIsPostLiked: (state) => (postId) => {
            return (state.findLikedPostsIndex(postId) != -1)
        },
        findLikedCommentsIndex: (state) => (commentId) => {
            return state.likedComments.findIndex(likedComment => likedComment.commentId == commentId)
        },
        checkIsCommentLiked: (state) => (commentId) => {
            return (state.findLikedCommentsIndex(commentId) != -1)
        },
        checkIsPostHidden: (state) => {
            return true
        },
    }, 
    actions: {
        async fetchComments(postId) {
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()
            console.log("fetchComments", postId, loadingStore.isLoading)
            const querySnapshot = await getDocs(query(collection(db, "comments"), where("postId", "==", postId)));
            let comments = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                comments.push({id:doc.id, ...doc.data()})
            });
            this.comments = _.orderBy(comments, ['createdOn'], ['desc']);

            this.fetchCommentsLikesByUser()
            loadingStore.onLoadingFinish()
            console.log("finsih loading", loadingStore.isLoadingFinish)

        },
        async addComment(postId, comment) {
            const loadingStore = useLoadingStore()
            const authStore = useAuthStore()
            loadingStore.onLoading("Adding Comment")
            try {
                const commentObj = {
                    createdOn: Timestamp.now(),
                    content: comment.content,
                    postId: postId,
                    userId: comment.userId,
                    userName: authStore.currentUser.displayName,
                    userPhotoURL: authStore.currentUser.photoURL,
                    likes: 0
                }
    
                const docRef = await addDoc(collection(db, "comments"), commentObj);
                await updateDoc(doc(db, "posts", postId), { comments: increment(1) }) 
                console.log("Document written with ID: ", docRef.id);
                console.log(commentObj)
                this.comments.push({id:docRef.id, ...commentObj});
                this.comments = _.orderBy(this.comments, ['createdOn'], ['desc']);
                comment.content = ""
                loadingStore.onLoadingFinish()
            } catch (ex) {
                console.log("addcomment error", ex)
                loadingStore.onLoadingError(ex)
            }
        },
        async fetchPostsLikesByUser() {
            // const loadingStore = useLoadingStore()
            const authStore = useAuthStore()
            // loadingStore.onLoading()
            const q = query(collection(db, "likes"), where("userId", "==", authStore.currentUser.uid))

            const unsubscribe = onSnapshot(q, (snapshot)=>{
                let postslikesArray = []
                snapshot.forEach(doc => {
                    // console.log("snapshot.forEach", doc)
                    postslikesArray.push({id:doc.id, ...doc.data()}) 
                })

                this.likedPosts = postslikesArray;
            })

            // loadingStore.onLoadingFinish()
            // console.log("finsih loading", loadingStore.isLoadingFinish)
        },

        async fetchCommentsLikesByUser() {
            const loadingStore = useLoadingStore()
            const authStore = useAuthStore()
            loadingStore.onLoading()
            const q = query(collection(db, "commentlikes"), where("userId", "==", authStore.currentUser.uid))

            const unsubscribe = onSnapshot(q, (snapshot)=>{
                let commentslikesArray = []
                snapshot.forEach(doc => {
                    // console.log("snapshot.forEach", doc)
                    commentslikesArray.push({id:doc.id, ...doc.data()}) 
                })

                this.likedComments = commentslikesArray;
            })

            loadingStore.onLoadingFinish()
        },
        async toggleLikePost(postId) {
            const authStore = useAuthStore()
            let userId = authStore.currentUser.uid
            let docId = `${userId}_${postId}`

            
            let index = this.findLikedPostsIndex(postId)
            if(index != -1) {
                console.log("unlike", postId, docId, userId)
                await deleteDoc(doc(db, "likes", docId))
                await updateDoc(doc(db, "posts", postId), { likes: increment(-1) }) 
                this.likedPosts.splice(index, 1)
            } else {
                console.log("like", postId, docId, userId)

                //Uncaught (in promise) FirebaseError: Invalid collection reference. Collection references must have an odd number of segments, but likes/H0Z4ClvHjkfw8DMb2jjFQ1RKG7U2_4PeBLsCBKVTCQs6kE4vX has 2.
                await setDoc(doc(db, "likes", docId), {
                    postId: postId,
                    userId: userId
                })
                await updateDoc(doc(db, "posts", postId), { likes: increment(1) }) 
                this.likedPosts.push(postId)
            }
            
        },
        async toggleLikeComment(commentId) {
            const authStore = useAuthStore()
            let userId = authStore.currentUser.uid

            let docId = `${userId}_${commentId}`

            let index = this.findLikedCommentsIndex(commentId)
            if(index != -1) {
                await deleteDoc(doc(db, "commentlikes", docId))
                await updateDoc(doc(db, "comments", commentId), { likes: increment(-1) }) 
                this.likedComments.splice(index, 1)
            } else {
                await setDoc(doc(db, "commentlikes", docId), {
                    commentId: commentId,
                    userId: userId,
                })
                await updateDoc(doc(db, "comments", commentId), { likes: increment(1) }) 
                this.likedComments.push(commentId)

                console.log("toggleLikeComment", this.likedComments)
            }
        },
        async fetchPosts() {
            //TODO: prefetch first 50 posts? 
            console.log("in fetchPosts");
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()

            const q = query(collection(db, "posts"))

            // const first = query(collection(db, "posts"), orderBy("createdOn", "desc"), limit(10));
            // const documentSnapshots = await getDocs(first);
            // documentSnapshots.forEach((doc) => {
            //     this.posts.push({id:doc.id, ...doc.data()})
            // });

            // let newPostCount = 0;
            const unsubscribe = onSnapshot(q, (snapshot)=>{
                // console.log("querySnapshot", snapshot.size);
                // console.log(snapshot.docChanges().length)

                snapshot.docChanges().forEach(change => {
                    if (change.type === 'added'){
                        if (!change.doc.metadata.hasPendingWrites) {
                            this.newPostCount++;
                        }
                        let index = this.posts.findIndex(statePost => statePost.id == change.doc.id)

                        if(index == -1) { 
                            this.posts.push({id:change.doc.id, ...change.doc.data()}) 
                        }
                    } else if (change.type === 'modified') {
                        let index = this.posts.findIndex(statePost => statePost.id == change.doc.id)
                        if(index != -1) {            
                            this.posts[index] = {id:change.doc.id, ...change.doc.data()};
                        }
                    }
                })

                if(!this.isPostFetched) {
                    this.newPostCount = 0;
                    this.isPostFetched = true;
                }
            })
            loadingStore.onLoadingFinish()

            this.fetchPostsLikesByUser()

        },

        async fetchPostbyPostId(postId) {
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()

            const docRef = doc(db, "posts", postId)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                loadingStore.onLoadingFinish()
                return {id: postId, ...docSnap.data()}
            } else {
                loadingStore.onLoadingError("no post found")
                console.log("no post found for postId: "+postId)
                return {}
            }
        }, 
        async addPost(post, fileAttachment, photoAttachment) {
            const mapStore = useMapStore()
            const ownerStore = useOwnerStore()
            const propertyStore = usePropertyStore()
            const authStore = useAuthStore();
            let pInfo = {pid: mapStore.PID, Address: mapStore.selectedAddress}

            if(propertyStore.property) {
                pInfo = propertyStore.property
            }
            if (mapStore.geoHash) {
                pInfo = {geoHash: mapStore.geoHash, ...pInfo};
            }
            let deviceToken = [];
            const querySnapshot = await getDocs(query(collection(db, "users")));
            querySnapshot.forEach(async (docData) => {
                if(typeof docData.data().deviceToken !== 'undefined'
                && docData.data().deviceToken.length > 0) {
                    deviceToken = deviceToken.concat(docData.data().deviceToken);
                }
                if(typeof docData.data().webToken !== 'undefined' 
                && docData.data().webToken.length > 0) {
                    deviceToken = deviceToken.concat(docData.data().webToken);
                }
            });
            if(deviceToken.length > 0) {
                deviceToken = deviceToken.filter(t => t !== authStore.mobileToken).filter(t => t !== authStore.webToken);
            }
            const isTaggedPost = post.tagParcel;
            let postToAdd = {
                createdOn: Timestamp.now(),
                deleted:false,
                title: post.title,
                content: post.content,
                userId: authStore.currentUser.uid,
                userName: authStore.currentUser.displayName,
                userPhotoURL: authStore.currentUser.photoURL,
                groupId: post.groupId? post.groupId: null, 
                comments: 0,
                likes: 0,
                tagParcel: isTaggedPost,
                ownerinfo: isTaggedPost? ownerStore.owner : null,
                propinfo: isTaggedPost? pInfo : null,
                centre: isTaggedPost? mapStore.selectedCentre.flatCoordinates: null,
                sendTo: deviceToken
            }

            let fileURL = "";
            let photoURL = "";
            if(fileAttachment.value) {
                fileURL = await this.uploadFile(fileAttachment.value, "file")
                postToAdd.fileName = fileAttachment.value.name
                postToAdd.fileURL = fileURL
            }

            if(photoAttachment.value) {
                photoURL = await this.uploadFile(photoAttachment.value, "image")

                postToAdd.photoURL = photoURL;
            }
         
            const docRef = await addDoc(collection(db, "posts"), postToAdd);
            console.log("Document written ref: ", docRef);
            
            post.content = "";
            post.title = "";
            post.groupId = "";

            fileAttachment.value = null;
            photoAttachment.value = null;
    
            return true;
        },

        async uploadFile(file, uploadType) {

            const storage = getStorage();
            const authStore = useAuthStore();

            return new Promise((resolve, reject) => {

                let storagePathPrefix = `users/${authStore.currentUser.uid}/`;
                
                if(uploadType === 'image'){
                    storagePathPrefix += "images"
                } else {
                    storagePathPrefix += "attachments"
                }

                const fbTimestampToDate = Timestamp.now().toDate()
                const year = fbTimestampToDate.getFullYear()
                const month = fbTimestampToDate.getMonth()+1
                const date = fbTimestampToDate.getDate()

                const time = fbTimestampToDate.getTime()

                // console.log(`${storagePathPrefix}/${year}-${month}-${date}/${time}-${file.name}`)
                const fileRef = ref(storage, `${storagePathPrefix}/${year}-${month}-${date}/${time}-${file.name}`);

                const uploadTask = uploadBytesResumable(fileRef, file);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    }, 
                    (error) => {
                    // Handle unsuccessful uploads
                    console.log("error upload profile image", error)
                    reject(error)
                    }, 
                    () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        console.log('File available at', downloadURL);
                        resolve(downloadURL);
                    });
                    }
                );
            })
        }, 
        async softDeletePost(postId) {
            if(this.canPostBeDeleted(postId)){
                let index = this.posts.findIndex(statePost => statePost.id == postId)

                await updateDoc(doc(db, "posts", postId), {
                    deleted: true
                });
                // this.posts[index].deleted = true;
            }
        },
        async fetchLikedUserList(postId) {
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()
            const querySnapshot = await getDocs(query(collection(db, "likes"), where("postId", "==", postId)))
            this.likes = []
            querySnapshot.forEach(async (docData) => {
                const userRef = doc(db, "users", docData.data().userId)
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    loadingStore.onLoadingFinish()
                    this.likes.push(docSnap.data())
                } else {
                    loadingStore.onLoadingError("no user found")
                    console.log("no user found for postId likes: "+postId)
                }
            });

            loadingStore.onLoadingFinish()
            console.log("finsih loading", loadingStore.isLoadingFinish)
        },
        async fetchCommentLikedUserList(commentId) {
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()

            const querySnapshot = await getDocs(query(collection(db, "commentlikes"), where("commentId", "==", commentId)))
            this.commentLikes = []
            console.log(querySnapshot.size)
            querySnapshot.forEach(async (docData) => {
                const userRef = doc(db, "users", docData.data().userId)
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    loadingStore.onLoadingFinish()
                    this.commentLikes.push(docSnap.data())
                } else {
                    loadingStore.onLoadingError("no user found")
                    console.log("no user found for commentId likes: "+commentId)
                }
            });

            loadingStore.onLoadingFinish()
            console.log("finsih loading", loadingStore.isLoadingFinish)
        },
    }
})


