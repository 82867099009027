<template>
  <ion-page :style="keyboardOffsetStyle">
    <ion-content scroll-y="false" class="ion-padding-top">
    <div class="map-search-panel" v-if="!showMergePanel">
      <!-- <ion-buttons slot="primary">
          <ion-button>
            <ion-icon slot="icon-only" :icon="search"></ion-icon>
          </ion-button>
        </ion-buttons> -->
        <!-- v-show="showTopFloatingButton" -->
      <ion-searchbar
        
        placeholder="Search by address"
        @ionChange="searchbarInput"
        @ionFocus="searchBarOnFocus = true"
        @ionBlur="searchBarOnFocus = false"
        @ionClear="searchResults = []"
        debounce="500"
        ref="searchbarEle"
      ></ion-searchbar>
      
      <div class="map-search-result-list">
        <ion-list v-if="searchResults.length > 0">
          <ion-item
            lines="none"
            v-for="(result, index) in searchResults"
            :key="index"
            @click="searchResultOnClick(result)"
          >
            {{ result.address.name }}
          </ion-item>
        </ion-list>
        <!-- <ion-label v-else> Input address to search. </ion-label> -->
      </div>
    </div>
    <div class="merge-panel" v-if="showMergePanel">
      <ion-buttons slot="primary">
        <ion-button
          color="success"
          v-if="landMergerStore.docIds.length > 1"
          @click="saveLandMerge()"
        >
          Merge
        </ion-button>
        <ion-button
          color="danger"
          v-if="landMergerStore.docIds.length == 1"
          @click="saveLandUnMerge()"
        >
          Unmerge
        </ion-button>
        <ion-button color="dark" @click="cancelLandMergerMode()">
          Cancel
        </ion-button>
      </ion-buttons>
    </div>
    <!-- <ion-header v-if="!landMergerMode">
      <ion-toolbar>
        <ion-title size="large">Map</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <!-- <ion-header v-if="landMergerMode">
      <ion-toolbar>
        <ion-buttons slot="primary">
          <ion-button
            v-if="landMergerStore.docIds.length > 1"
            @click="saveLandMerge()"
          >
            Merge
          </ion-button>
          <ion-button color="danger" v-else @click="saveLandMerge()">
            Unmerge
          </ion-button>
          <ion-button @click="cancelLandMergerMode()"> Cancel </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header> -->

    
      <ion-backdrop
        v-if="showBackdrop"
        @click="showBackdrop = false"
      ></ion-backdrop>
      <div id="mapView" ref="mapView" class="map-view">
        <div ref="popupRef" class="ol-popup">
          <a @click="popupcloser" class="ol-popup-closer"></a>
          <div class="ol-popup-content" v-html="popupContent"></div>
          <ion-row class="ion-justify-content-evenly">
            <div
              style="font-size: 18px"
              @click="async () => {
                if(!(propertyStore.property && ownerStore.owner)){
                  const toast = await toastController.create({
                    message: 'Owner/property info not found.',
                    duration: 2000,
                  });
                  toast.present();
                } else {
                  router.push({ name: 'propertyOwnerInfo' })
                }
                  
              }"
            >
              <ion-icon
                :icon="informationCircleOutline"
                slot="icon-only"
                :style="!(propertyStore.property && ownerStore.owner)?'color:var(--ion-color-warning)':''"
              ></ion-icon>
            </div>
            <div style="font-size: 18px" @click="showStreetviewPage">
              <font-awesome-icon icon="street-view" slot="icon-only" />
            </div>
            <div style="font-size: 18px">
              <bca-button />
            </div>
            <div style="font-size: 18px" @click="onBookmarkIconClick">
              <ion-icon
                v-if="isBookmarked"
                :icon="bookmark"
                :style="`color: ${bookmarkStore.getColorByGeoHash(mapStore.geoHash)}`"
                slot="icon-only"
              ></ion-icon>
              <ion-icon
                v-else
                :icon="bookmarkOutline"
                slot="icon-only"
              ></ion-icon>
            </div>
            
          </ion-row>
        </div>
      </div>
      
      <bookmark-group-modal 
        :isEditMode="false" 
        :isOpen="isBookmarkGroupModalOpen" 
        :modalTitle="'Select Bookmark Group'"
        @onBookmarkGroupModalClose="() => isBookmarkGroupModalOpen = false"
        @onBookmarkGroupSelect="(bookmarkGroup) => handleBookmarkGroupSelect(bookmarkGroup)"
      />

<!-- v-show="showTopFloatingButton" -->
      <!-- <ion-fab
        id="concated-bottom-button-menu"
        
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        style="margin-bottom: 5.5rem"
      >
        <ion-fab-button color="light">
          <ion-icon :icon="chevronBackCircleOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="start">
          <ion-fab-button
            @click="(event)=>{
              event.stopImmediatePropagation();
              router.push({ name: 'bookmarks' })
            }"
            ><font-awesome-icon class="faIcon" icon="book-bookmark"
          /></ion-fab-button>
          <ion-fab-button @click="onClickLocateMe"
            ><ion-icon :icon="navigateOutline"></ion-icon
          ></ion-fab-button>
          <ion-fab-button @click="onClickRefreshMap"
            ><ion-icon :icon="refreshOutline"></ion-icon
          ></ion-fab-button>
        </ion-fab-list>
      </ion-fab> -->
              <!-- v-show="showTopFloatingButton" -->


      
      <ion-fab
        id="concated-button-menu"
        style="margin-top: 98px"
        vertical="top"
        horizontal="end"
        slot="fixed"
      >
        <ion-fab-button color="light">
          <ion-icon :icon="layersOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="bottom">
          <ion-fab-button
          
            @click="toggleSatelliteLayer"
            :color="isShowingSatelliteLayer ? 'primary' : ''"
          >
            <font-awesome-icon class="faIcon" icon="satellite" size="2x"/>
          </ion-fab-button>
          <ion-fab-button
            @click="toggleBookmarkLayer"
            :color="isShowingBookmarkLayer ? 'primary' : ''"
          >
            <ion-icon :icon="bookmarks"></ion-icon>
          </ion-fab-button>
          <ion-fab-button
            @click="toggleChatLayer"
            :color="isShowingChatLayer ? 'primary' : ''"
          >
            <ion-icon :icon="chatboxEllipsesOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button
            id="trans-chip"
            @click="onTransFabClick"
            :color="isShowingTransactionLayer ? 'primary' : ''"
          >
            <font-awesome-icon class="faIcon" icon="file-invoice-dollar" size="2x"/>
          </ion-fab-button>
          <!-- <ion-fab-button v-show="isShowingTransactionLayer" @click="clearTransLayer">
            <ion-icon :icon="funnelOutline"></ion-icon>
          </ion-fab-button> -->
          <!-- <ion-fab-button @click="toggleLandMerger">
            <font-awesome-icon class="faIcon" icon="object-group" size="2x"/>
          </ion-fab-button> -->
        </ion-fab-list>

        <ion-fab-list side="start">
          <ion-fab-button v-show="isShowingTransactionLayer" @click="onTransactionFilterClick" :color="isTransFilterOpen ? 'primary' : ''">
              <font-awesome-icon class="faIcon" icon="filter" size="2x"/>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-fab
        id="concated-button-menu"
        style="margin-top: 36px;"
        vertical="top"
        horizontal="end"
        slot="fixed"
      >
        <ion-fab-button @click="onClickLocateMe"  color="light"
          ><ion-icon :icon="navigateOutline"></ion-icon
        ></ion-fab-button>
      </ion-fab>
      <div v-show="!showMergePanel">
        <div class="cupertino-pane-main" ref="mainCupertinoRef" >
          <sheet-modal />
        </div>
      </div>
      <!-- setting please find: https://panejs.com/settings.html -->
      <!-- <v-cupertino
        :id="1"
        @did-dismiss="dismissDrag($event)"
        @drag-end="cupertionDragEnd($event)"
        :isPresent="mapStore.isSheetModalOpen"
        :drawerOptions="drawerOptions"
        ref="mainCupertinoRef"
      >
        <sheet-modal />
      </v-cupertino> -->
      <!-- <ion-modal
        :is-open="mapStore.isSheetModalOpen"
        cssClass="sheetModal"
        :breakpoints="[0.2, 0.5, 1]"
        :initialBreakpoint="0.2"
        :backdrop-dismiss="false"
        :backdrop-breakpoint="0.5"
        mode="ios"
        @didDismiss="mapStore.isSheetModalOpen = false"
      >
        <sheet-modal @toggleLandMergerMode="toggleLandMerger()" />
      </ion-modal> -->
      <ion-modal
        :is-open="isTransFilterOpen"
        :breakpoints="[0, 1]"
        :initialBreakpoint="1"
        :show-backdrop="true"
        @didDismiss="isTransFilterOpen = false"
      >
        <!-- <ion-header>
          <ion-toolbar>
            <ion-title>Titie</ion-title>
            <ion-buttons>
              <ion-button @click="() => {
                  toggleTransactionLayer();
                  isTransFilterOpen = false;
                }">Apply</ion-button>
              <ion-button color="dark" @click="() => (isTransFilterOpen = false)">Cancel</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <TransactionFilter
            class="ion-padding-vertical"
            @onApply="
              () => {
                toggleTransactionLayer();
                isTransFilterOpen = false;
              }
            "
            @onCancel="() => (isTransFilterOpen = false)"
            
          />
        </ion-content> -->
        <TransactionFilter
            class="ion-padding-vertical"
            @onApply="
             async () => {
                mapStore.map.getLayers().getArray().filter(layer => layer.get('name') === 'recentTrans').forEach(layer => mapStore.map.removeLayer(layer));
                await initTransaction();
                isTransFilterOpen = false;
              }
            "
            @onCancel="() => (isTransFilterOpen = false)"
            
          />
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { Map, View } from "ol";
import * as olControl from "ol/control";
import * as olProj from "ol/proj";
import "../../../node_modules/ol-geocoder/dist/ol-geocoder.min.css";
import Geocoder from "ol-geocoder";
import { Style } from "ol/style";
import { Fill, Stroke, Icon } from "ol/style";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { Vector } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Control from "ol/control/Control";
// import { circular } from "ol/geom/Polygon";
import { fromLonLat } from "ol/proj";
// import { containsCoordinate } from "ol/extent";
import Overlay from "ol/Overlay";
import Select from "ol/interaction/Select";

// import { db } from "../../firebase";

//for API call
import axios from "axios";

import mapconfig from "./config/mapconfig";

import recentTransactions from "./recent-transactions";
// import landMerger from "./land-merger";

import { useRoute, useRouter } from "vue-router";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { isPlatform, useKeyboard } from "@ionic/vue";
import { Geolocation } from "@capacitor/geolocation";

import {
  useMapStore,
  usePropertyStore,
  useOwnerStore,
  useSocialStore,
} from "@/state";

import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  onBeforeUpdate,
  reactive,
  defineEmits,
  defineProps,
} from "vue";
// import LandMergerButton from "./land-merger-button.vue";
import { useLandMergerStore } from "./useLandMergerStore";
import { Capacitor } from "@capacitor/core";

import SocialPage from "@/components/social-page/social-page.vue";
import PostList from "@/components/social-page/post-list.vue";
import SheetModal from "@/components/sheet-modal/sheet-modal.vue";
import BcaButton from "@/components/bca-button.vue";
import BookmarkGroupModal from "@/components/userProfile/bookmarkGroupModal.vue"
import {
  heart,
  heartOutline,
  mapOutline,
  copyOutline,
  chatboxEllipsesOutline,
  checkmarkCircle,
  ellipseOutline,
  bookmarks,
  bookmark,
  bookmarkOutline,
  informationCircleOutline,
  search,
  chevronDownOutline,
  navigateOutline,
  close,
  list,
  layersOutline,
  chevronUpCircleOutline,
  refreshOutline,
  closeOutline,
  funnelOutline,
  chevronBackCircleOutline
} from "ionicons/icons";
import {
  IonPage,
  IonModal,
  IonContent,
  IonCheckbox,
  // IonThumbnail,
  // IonAvatar,
  // IonCheckbox,
  // IonInput,
  IonItem,
  // IonItemOption,
  // IonItemOptions,
  // IonItemSliding,
  IonList,
  IonLabel,
  // IonRadio,
  // IonToggle,
  IonBackdrop,
  IonFab,
  IonFabList,
  IonFabButton,
  IonIcon,
  IonCard,
  IonCardTitle,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonSearchbar,
  IonChip,
  IonHeader,
  IonFooter,
  IonMenu,
  IonMenuToggle,
  onIonViewWillEnter,
  onIonViewDidEnter,
  onIonViewWillLeave,
  onIonViewDidLeave,
  modalController,
  alertController,
  toastController,
} from "@ionic/vue";
import { useModalStore } from "../sheet-modal/useModalStore";
import VCupertino from "v-cupertino";
import { CupertinoPane } from "cupertino-pane";
import TransactionFilter from "@/components/sheet-modal/transaction-filter.vue";

import * as olStyle from "ol/style";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import {
  // ActionPerformed,
  // PushNotificationSchema,
  PushNotifications,
} from "@capacitor/push-notifications";
import { useAuthStore } from "../../router/useAuthStore";
import { Keyboard } from "@capacitor/keyboard";
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy";
import { useBookmarkStore } from "@/state/bookmarkStore";
import { useTransactionFilterStore } from "./../sheet-modal/useTransactionFilterStore";
import { format, parseISO, getDate, getMonth, getYear } from "date-fns";

const searchResults = ref([]);
// const showSearchResults = ref(false);
const searchBarOnFocus = ref(false);

const socialStore = useSocialStore();
const showBottomFloatingButton = ref(true);
const showTopFloatingButton = ref(true);
const showMergePanel = ref(false);
const showMap = ref(true);
const showBackdrop = ref(false);

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)
const proxyurl2 = "https://index2.laurel.workers.dev/?";

const Perry_extent = [
  -13708573.09907316, 6317899.993763422, -13706121.72827147, 6319568.366776173,
];
const router = useRouter();
const authStore = useAuthStore();

const mapView = ref(null); // get map elemet ref
let popup;

const popupRef = ref(null);
const popupContent = ref("");

const isBookmarked = ref(false);

// const createpostVisible = ref(false); //UI control createpost visible
// const ownerinfo = ref(null); // input for createpost
// const propinfo = ref(null); // input for createpost
const selectedParcel = ref(null);
const selectedCentre = ref(null);
const selectedAddress = ref(null);

const isShowingSatelliteLayer = ref(false);
const isShowingBookmarkLayer = ref(false);
const isShowingChatLayer = ref(false);
const isShowingTransactionLayer = ref(false);
const isTransFilterOpen = ref(false);

const mapStore = useMapStore();
const bookmarkStore = useBookmarkStore();
const propertyStore = usePropertyStore();
const ownerStore = useOwnerStore();
const landMergerStore = useLandMergerStore();
const modalStore = useModalStore();
const transFilterStore = useTransactionFilterStore()

// const mainCupertinoRef = ref(VCupertino);
const mainCupertinoRef = ref(null);

const landMergerMode = ref(false);

// constants
const featureChatsMarker = "chatsMarker";
const featureChatsCluster = "chatsCluster";
const bottomOffset = ref(51);

const props = defineProps({
  doSearch: { type: Boolean, required: false, default: false },
});

// var settings;
var myPane;

const { isOpen, keyboardHeight } = useKeyboard();
const keyboardOffsetStyle = reactive({
  top: -keyboardHeight.value + "px",
});

watch(keyboardHeight, () => {
  if(isOpen.value === true) {
    if(!searchBarOnFocus.value) {
      keyboardOffsetStyle.top = -keyboardHeight.value + "px";
      showBottomFloatingButton.value = false;
      showTopFloatingButton.value = false;
      bottomOffset.value = 0;
    }
  } else {
    keyboardOffsetStyle.top = 0 + "px";
    showBottomFloatingButton.value = true;
    showTopFloatingButton.value = true;
    bottomOffset.value = 51;
    RefreshMap()
  }
});

const deviceOffset = () => {
    const sat = getComputedStyle(document.documentElement).getPropertyValue("--ion-safe-area-top");
    const sab = getComputedStyle(document.documentElement).getPropertyValue("--ion-safe-area-bottom");
    return parseFloat(sat.replace('px', '')) + parseFloat(sab.replace('px', ''));
}

const searchAddress = (address) => {
  const url = proxyurl2 + "https://geocoder.api.gov.bc.ca/addresses.geojson";

  return axios.get(url, { params: { addressString: address } });
};

const searchbarInput = (ev) => {
  // mapStore.strSearch = ev.target.value;
  // showSearchResults.value = true;

  if (ev.target.value.length >= 3) {
    searchAddress(ev.target.value).then(({ data }) => {
      // console.log("handleResponse", data.features);
      if (data && data.features && data.features.length) {
        // showSearchResults.value = true;
        searchResults.value = data.features.map(function (feature) {
          // console.log("handleResponse feature", feature);
          return {
            lon: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1],
            address: {
              // Simply return a name in this case, could also return road,
              // building, house_number, city, town, village, state,
              // country
              name: feature.properties.fullAddress,
            },
            bbox: feature.bbox,
          };
        });
      } else {
        // showSearchResults.value = false;
        searchResults.value = [];
      }
    });
  } else {
    // showSearchResults.value = false;
    searchResults.value = [];
  }
};

const searchResultOnClick = (result) => {
  // showSearchResults.value = false;
  searchResults.value = [];

  MapOnClick(
    olProj.transform([result.lon, result.lat], "EPSG:4326", "EPSG:3857")
  );

  // function remove_search_pin() {
  //   var remove_layer_name = "geocoder-layer";
  //   var layers_to_remove = [];
  //   mapStore.map.getLayers().forEach(function (layer) {
  //     var layer_name = layer.getProperties().name;
  //     if (layer_name && layer_name.match(remove_layer_name)) {
  //       layers_to_remove.push(layer);
  //     }
  //   });
  //   for (var i = 0; i < layers_to_remove.length; i++) {
  //     mapStore.map.removeLayer(layers_to_remove[i]);
  //   }
  // }
  // remove_search_pin();
};

const toggleLandMerger = async (event) => {
  event.stopImmediatePropagation();
  // const cupertino = mainCupertinoRef.value.cupertino;
  showMergePanel.value = true;

  if (popup) popup.setPosition(undefined);
  // console.log("toggleLandMerger cupertino", cupertino);
  // if (mapStore.isSheetModalOpen) cupertino.hide();
  // if (!myPane.isHidden()) myPane.moveToBreak("bottom"); 

  landMergerMode.value = true;
  landMergerStore.isMultipleSelectionOn = true;
  await nextTick();
  RefreshMap();
};

const saveLandMerge = () => {
    landMergerStore
      .saveResult()
      .then(async () => {
        const alert = await alertController.create({
          cssClass: "my-custom-class",
          header: "Saved",
          subHeader: "Land Merged",
          message: "The merged land is saved.",
          buttons: ["OK"],
        });
        await alert.present();

        const { role } = await alert.onDidDismiss();
        console.log("onDidDismiss resolved with role", role);
        cancelLandMergerMode();
        if (mapStore.hasSelectedArea) {
          MapOnClick(mapStore.selectedArea);
        }
      })
      .catch(async (err) => {
        const alert = await alertController.create({
          cssClass: "my-custom-class",
          header: "Error",
          subHeader: "Land Merge Save",
          message: `Error in saving the merged land.\n${err}`,
          buttons: ["OK"],
        });
        await alert.present();

        const { role } = await alert.onDidDismiss();
        console.log("onDidDismiss resolved with role", role);
      });
};

const saveLandUnMerge = () => {
  landMergerStore
    .deleteResult()
    .then(async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Saved",
        subHeader: "Land Unmerged",
        message: "Lands unmerged.",
        buttons: ["OK"],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
      cancelLandMergerMode();
      if (mapStore.hasSelectedArea) {
        MapOnClick(mapStore.selectedArea);
      }
    })
    .catch(async (err) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Error",
        subHeader: "Land Unmerge Save",
        message: `Error in unmerging lands.\n${err}`,
        buttons: ["OK"],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    });
}

const cancelLandMergerMode = async () => {
  // const cupertino = mainCupertinoRef.value.cupertino;

  showMergePanel.value = false;
  
  landMergerMode.value = false;
  landMergerStore.isMultipleSelectionOn = false;
  await nextTick();
  RefreshMap();
};

onMounted(async () => {
  //onMount only run once, e.g. tab change: Map(will trigger onMount) -> post -> Map (will not trigger onMount)
  //onIonViewWillEnter run every time view is shown on screen

  await init();

  // mapStore.isSheetModalOpen = false;
  landMergerMode.value = false;
  window.addEventListener("orientationchange", function () {
    RefreshMap();
  });
});

// onIonViewDidLeave(() => {
//   console.log("Home page did leave");
// });

onIonViewWillEnter(async () => {
  await nextTick();
  setTimeout(() => {
    RefreshMap();
  }, 1000);
});

onIonViewWillLeave(() => {
  // if (mapStore.isSheetModalOpen) {
  //   mapStore.isSheetModalOpen = false;
  // }
  // mapStore.selectedArea = {};
  socialStore.filterPostPid = "";
  socialStore.filterGeoHash = "";
});

const route = useRoute();

onIonViewDidEnter(() => {
  if (mapStore.hasSelectedArea) {
    MapOnClick(mapStore.selectedArea);
    // mapStore.map.getView().setCenter(mapStore.selectedArea)
  }

  // console.log("onIonViewDidEnter", route.query.a, mapStore.strSearch);
  if (route.query && !(route.query.a == undefined || route.query.a == "")) {
    console.log("from map query address", route.query.a);
    searchAddress(route.query.a).then(({ data }) => {
      // console.log("doSearch handleResponse", data.features);
      if (data && data.features && data.features.length) {
        data.features.map(function (feature) {
          MapOnClick(
            olProj.transform(
              [
                feature.geometry.coordinates[0],
                feature.geometry.coordinates[1],
              ],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
        });

        // route.query.search = "false";
      }
    });
  }
});

const toggleSatelliteLayer = (event) => {
  event.stopImmediatePropagation()
  mapStore.map.getLayers().forEach(function (lyr) {
    if (lyr.get("name") === "GSatellite") {
      var is_visible = lyr.get("visible");
      lyr.setVisible(!is_visible);
      isShowingSatelliteLayer.value = !is_visible;
      return;
    }
  });
};

const toggleBookmarkLayer = (event) => {
  event.stopImmediatePropagation()
  mapStore.map.getLayers().forEach(function (lyr) {
    if (lyr.get("name") === "Bookmarks") {
      var is_visible = lyr.get("visible");
      lyr.setVisible(!is_visible);
      isShowingBookmarkLayer.value = !is_visible;
      return;
    }
  });
};

const toggleChatLayer = (event) => {
  event.stopImmediatePropagation()
  if (isShowingChatLayer.value == true) {
    mapStore.map.getLayers().forEach(function (lyr) {
      if (lyr.get("name") === "chats") {
        lyr.setVisible(false);
        isShowingChatLayer.value = false;
      }
    });
  } else {
    mapStore.map.getLayers().forEach(function (lyr) {
      if (lyr.get("name") === "chats") {
        lyr.setVisible(true);
        isShowingChatLayer.value = true;
        return;
      }
    });
  }
};
const onTransFabClick = (event) => {
  event.stopImmediatePropagation();
  isTransFilterOpen.value = false;
  toggleTransactionLayer();
}

const onTransactionFilterClick = (event) => {
    event.stopImmediatePropagation();
    isTransFilterOpen.value = !isTransFilterOpen.value;
}

const clearTransLayer = (event) => {
  event.stopImmediatePropagation()
  if(isShowingTransactionLayer.value) {
    mapStore.map.getLayers().getArray().filter(layer => layer.get('name') === "recentTrans").forEach(layer => mapStore.map.removeLayer(layer))
    isShowingTransactionLayer.value = false;
  }
}

const toggleTransactionLayer = () => {
  mapStore.map.getLayers().getArray()
  .filter(layer => layer.get('name') === "recentTrans")
  .forEach(function (lyr) {
    var is_visible = lyr.get("visible");
    lyr.setVisible(!is_visible);
    isShowingTransactionLayer.value = !is_visible;
  });
};

// const onClickRefreshMap = async (event) => {
//   console.log('refresh', event)
//   event.stopImmediatePropagation()
//   RefreshMap();
//   const toast = await toastController.create({
//     message: "Refreshing the map...",
//     duration: 2000,
//   });
//   return toast.present();
// };

const onClickLocateMe = (event) => {
  event.stopImmediatePropagation()
  //   Geolocation.getCurrentPosition().then(res => {
  //   const coords = [res.coords.longitude, res.coords.latitude];
  //   const source = new Vector();
  //     source.clear(true);
  //     source.addFeatures([new Feature(new Point(fromLonLat(coords)))]);
  //     mapStore.map.getView().fit(source.getExtent(), {
  //       maxZoom: 17,
  //       duration: 500,
  //     });
  // });
  if (Capacitor.isNativePlatform()) {
    Geolocation.getCurrentPosition().then((res) => {
      const coords = [res.coords.longitude, res.coords.latitude];
      const source = new Vector();
      source.clear(true);
      source.addFeatures([new Feature(new Point(fromLonLat(coords)))]);
      mapStore.map.getView().fit(source.getExtent(), {
        maxZoom: 17,
        duration: 500,
      });
    });
  } else {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          const coords = [pos.coords.longitude, pos.coords.latitude];
          const source = new Vector();
          source.clear(true);
          source.addFeatures([new Feature(new Point(fromLonLat(coords)))]);
          mapStore.map.getView().fit(source.getExtent(), {
            maxZoom: 17,
            duration: 500,
          });
        },
        function (error) {
          alert(`ERROR: ${error.message}`);
        },
        {
          enableHighAccuracy: true,
        }
      );
    }
  }
};

const RefreshMap = () => {
  if (mapStore.map) {
    console.log("Refresh Map");

    mapStore.map.updateSize();
    mapStore.map.renderSync();
  }
};

const getPaneTransformY = () => {
  const translateYRegex = /\.*translateY\((.*)px\)/i;
  const paneEl = document.querySelector(".pane");
  return paneEl
    ? parseFloat(translateYRegex.exec(paneEl.style.transform)[1])
    : 0;
};


const searchbarEle = ref(null)


const initCupertinoPane = () => {
  console.log("initCupertinoPane", myPane);
  let content = document.querySelector("#sheet-modal-content");
  let topHeight = window.innerHeight;
  // let middleHeight = topHeight / 2;
  let middleHeight = 310;
  // if(isPlatform("iphone")) {
  //   drawerOptions.breaks.top = { enabled: true, height: window.screen.height - deviceOffset()}
  // }

  console.log("searchbarEle", searchbarEle.value.$el.getBoundingClientRect().bottom)
const drawerOptions = {
  bottomOffset: bottomOffset.value,
  fastSwipeSensivity: 15,
  touchMoveStopPropagation: true,
  bottomClose: false,
  initialBreak: "bottom",
  lowerThanBottom: false,
  buttonDestroy: false,
  // followerElement: "#concated-bottom-button-menu",
  breaks: {
    middle: { enabled: true, height: 310 }, 
    top: { enabled: true, height: window.innerHeight - bottomOffset.value - searchbarEle.value.$el.getBoundingClientRect().bottom}
    // top: { enabled: true, height: }
  },
  topperOverflow: false,
  dragBy: [".list-header", ".list-header-inner"],
  // upperThanTop: true,
};


  myPane = new CupertinoPane(".cupertino-pane-main", drawerOptions);
  myPane.present({ animate: true });

  var ele = document.getElementsByClassName("cupertino-pane-main");

  myPane.on("onDrag", (ev) => {
    if (myPane.currentBreak() !== "bottom") showMap.value = false;
    // content.style.height = '300px';
    content.style.height = `${
      window.screen.height - getPaneTransformY() - content.offsetTop + - bottomOffset.value
    }px`;

    content.style.paddingBottom = '55px';

  });

  // myPane.on("onDidPresent", (ev) => {
  //   // content.setAttribute("style", "overflow-y: scroll !important");
  //   content.style.height = `${middleHeight - content.offsetTop}px`;
  // });

  myPane.on("onTransitionEnd", (ev) => {
    // showBackdrop.value = false;
    setTimeout(() => {
      if (myPane.currentBreak() === "top") {
        // showBackdrop.value = true;
        // content.setAttribute("style", "overflow-y: auto !important");
        content.style.height = `${topHeight - content.offsetTop - 70}px`;
      }
      if (myPane.currentBreak() === "middle") {
        content.style.height = `${middleHeight - content.offsetTop}px`;
      }
      // testingMSG.value = content.style.height;
    }, 200);
    // showBackdrop.value = false;
    // console.log(
    //   "onTransitionEnd",
    //   ev,
    //   mainCupertinoRef.value.getBoundingClientRect().top
    // );
    // Math.round(pane.value.getBoundingClientRect().y));
    // if (myPane.currentBreak() !== "top") {
    //   showMap.value = true;

    // }

    // top: window.screen.height - (135 * 0.35)
    // middle: 336
    // bottom: 136
    // let modalHeight = window.innerHeight - e;
    // if (modalHeight == 136) {
    //   showBottomFloatingButton.value = true;
    // } else {
    //   showBottomFloatingButton.value = false;
    // }
    // console.log(modalHeight);
    // if (myPane.currentBreak() !== "top") showBackdrop.value = false;
    cupertionDragEnd(mainCupertinoRef?.value.getBoundingClientRect().top);
  });
};

const init = async () => {
  const isPushNotificationsAvailable =
    Capacitor.isPluginAvailable("PushNotifications");
  if (Capacitor.isNativePlatform()) {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token) => {
      authStore.updateUserDeviceToken(token.value);
      // alert('Push registration success, token: ' + token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error) => {
      alert("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        // alert('Push received: ' + JSON.stringify(notification));
        alertController
          .create({
            header: `${notification.title}`,
            message: `<p>Message: <strong>${notification.body}</strong></p>`,
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {
                  console.log("Confirm Cancel:", blah);
                },
              },
              {
                text: "Read",
                handler: () => {
                  router.push({ path: "post/g/all" });
                },
              },
            ],
          })
          .then((a) => a.present());
      }
    );
    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        router.push({ path: "post/g/all" });
        // alert('Push action performed: ' + JSON.stringify(notification));
      }
    );

    Keyboard.setAccessoryBarVisible({ isVisible: true });

    Geolocation.requestPermissions().then((result) => {
      if (result.location === "granted") {
        LocationAccuracy.canRequest().then((canRequest) => {
          if (canRequest) {
            // the accuracy option will be ignored by iOS
            LocationAccuracy.request(
              this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
            ).then(
              () => console.log("Request successful"),
              (error) =>
                console.log("Error requesting location permissions", error)
            );
          }
        });
      } else {
        // show error
      }
    });
  } else {
    // console.log('Requesting permission...');
    // Notification.requestPermission().then((permission) => {
    //   if (permission === 'granted') {
    //     console.log('Notification permission granted.');
    //   }
    // });
    // // Get registration token. Initially this makes a network call, once retrieved
    // // subsequent calls to getToken will return from cache.
    // const messaging = getMessaging();
    // getToken(messaging, { vapidKey: 'BLay2iLGiApAc1hLXmtczXIaU4d-V0k1E6BrldUfo5hGLV648g7Xb32ZrYAMzi53HXICeO7IK8PKCCJBSBPGXxg' }).then((currentToken) => {
    //   if (currentToken) {
    //     authStore.updateUserWebToken(currentToken);
    //     // Send the token to your server and update the UI if necessary
    //     // ...
    //   } else {
    //     // Show permission request UI
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // ...
    // });
    // onMessage(messaging, (payload) => {
    //   console.log('Message received', payload);
    //   // ...
    // });
  }

  await initMap();

  await bookmarkStore.fetchBookmarks();
  await bookmarkStore.fetchBookmarkGroups();
  await initBookmark();

  if (!myPane) {
    initCupertinoPane();
  }

  await initTransaction();

  if (!socialStore.isPostFetched) {
    socialStore.fetchPosts();
  }
  watch(
    socialStore,
    ({ posts }) => {
      initChat();
    },
    { deep: true }
  );

  initPopup();
};

const initTransaction = () => {
let recentTrans = [];
const transDisplay = ref([]);
const realnetFileType = ".json";

axios
  .get(`/assets/data/RealnetOutput${transFilterStore.selectedYear}${realnetFileType}`)
  .then((response) => {
      console.log('marers', response );
    if (response && response.status === 200) {
      recentTrans = response.data;
      const featureTypeMarker = "transactionMarker";
      const featureTypeCluster = "transactionCluster";
      const recentTransClusterRadius = 15;
      const recentTransClusterDistance = 40;

      var markerImage = new olStyle.Icon({
        anchor: [0.5, 1],
        crossOrigin: "anonymous",
        scale: 0.1,
        src: "/assets/img/home-map-marker.png",
      });
      var clusterImage = new olStyle.Circle({
        radius: recentTransClusterRadius,
        stroke: new olStyle.Stroke({ color: "#fff" }),
        fill: new olStyle.Fill({ color: "#0099e6" }),
      });
      var clusterTextFill = new olStyle.Fill({ color: "#fff" });

      // Create the Recent Transaction Cluster Layer
      const recentTransLayer = new VectorLayer({
        title: "Recent Transaction",
        name: "recentTrans",
        visible: false,
        style: function (feature) {
          var size = feature.get("features").length;
          var style;
          if (size == 1) {
            feature.featureType = featureTypeMarker;
            style = new olStyle.Style({
              image: markerImage,
            });
          } else {
            feature.featureType = featureTypeCluster;
            style = new olStyle.Style({
              image: clusterImage,
              text: new olStyle.Text({
                text: size.toString(),
                fill: clusterTextFill,
              }),
            });
          }
          return style;
        },
      });
      var markers = [];
      transDisplay.value = recentTrans;
      transDisplay.value = transDisplay.value
        .filter((tran) => {
          var found = false;
          found =
            getMonth(parseISO(tran.EventDate)) >= transFilterStore.selectedMonthsLower &&
            getMonth(parseISO(tran.EventDate)) <= transFilterStore.selectedMonthsUpper &&
            tran.Price >= transFilterStore.selectedPricesLower &&
            tran.Price <= transFilterStore.selectedPricesUpper;

          if (!found) return false;

          const checkboxInputsAsset = transFilterStore.selectedAssetTypes;
          const isAllcheckedAsset = checkboxInputsAsset.every((checkbox) => checkbox.isChecked);

          var isMatch = false;
          if(!isAllcheckedAsset) {
            // Check if the item has one of the values in the checkbox input array
            isMatch = checkboxInputsAsset.includes(tran.AssetType);
          }

          if(!isMatch)
            return false
          found = found && isMatch
          
          const checkboxInputsSale = transFilterStore.selectedSaleTypes;

          const isAllcheckedSale = checkboxInputsSale.every((checkbox) => checkbox.isChecked);

          let isMatch2 = false;
          if(!isAllcheckedSale) {
            let saletype = tran.SaleType == null ? "Others" : tran.SaleType.split(" - ")[0];
            isMatch2 = checkboxInputsSale.includes(saletype);
          }

          if(!isMatch2)
            return false
          found = found && isMatch2

          return found;
        })
        .map((filteredResult, i) => {
          let marker = new Feature({
            geometry: new Point(
              fromLonLat([filteredResult.Longitude, filteredResult.Latitude])
            ),
            index: i,
          });
          markers.push(marker);
          return filteredResult;
        });
      mapStore.filteredTransactions = transDisplay.value;
      let recentTransClusterSource = new ClusterSource({
      distance: recentTransClusterDistance,
      source: new VectorSource({ features: markers }),
      });
      recentTransLayer.setSource(recentTransClusterSource);
      recentTransLayer.setZIndex(1);
      mapStore.map.addLayer(recentTransLayer);
      console.log('add layer')
      if(isShowingTransactionLayer.value) {
          mapStore.map.getLayers().getArray()
        .filter(layer => layer.get('name') === "recentTrans")
        .forEach(function (lyr) {
          var is_visible = lyr.get("visible");
        console.log('visible', is_visible)
          lyr.setVisible(!is_visible);
        });
      }
    }
  });
  
}

const initBookmark = () => {
  mapStore.map.getLayers().forEach(function (lyr) {
    if (lyr.get("name") === "Bookmarks") {
      //set bookmark polygons on map
      if (bookmarkStore.hasBookmark) {
        bookmarkStore.bookmarks.map((bookmark) => {
          let polygon = new Polygon(JSON.parse(bookmark.coordinates));
          let feature = new Feature(polygon);
          feature.setId(bookmark.geoHash);
          
          feature.setStyle(new Style({
            fill: new Fill({
              color: bookmarkStore.getColorByGroupId(bookmark.groupId),
            }),
          }))

          lyr.getSource().addFeature(feature);
        });
      }
    }
  });
};

const initChat = () => {
  const chatsClusterDistance = 35;
  const chatsClusterRadius = 8;

  var clusterImage = new olStyle.Circle({
    radius: chatsClusterRadius,
    stroke: new olStyle.Stroke({ color: "#fff" }),
    fill: new olStyle.Fill({ color: "red" }),
  });
  var clusterTextFill = new olStyle.Fill({ color: "#fff" });

  // Create the Recent Transaction Cluster Layer
  const chatsLayer = new VectorLayer({
    title: "Chats",
    name: "chats",
    visible: false,
    style: function (feature) {
      var size = feature.get("features").length;
      var style;

      feature.featureType = featureChatsCluster;
      style = new olStyle.Style({
        image: clusterImage,
        text: new olStyle.Text({
          text: size.toString(),
          fill: clusterTextFill,
        }),
      });
      return style;
    },
    zIndex: 0,
  });

  var markers = [];
  // chatsLayer.setZIndex(-1);

  if (socialStore.posts) {
    socialStore.posts.map((post, i) => {
      if (post.centre) {
        let lonlat = olProj.transform(post.centre, "EPSG:3857", "EPSG:4326");
        let lon = lonlat[0];
        let lat = lonlat[1];

        let marker = new Feature({
          geometry: new Point(fromLonLat([lon, lat])),
          index: i,
        });
        markers.push(marker);
      }
    });
  }

  let chatsClusterSource = new ClusterSource({
    distance: chatsClusterDistance,
    source: new VectorSource({ features: markers }),
  });
  chatsLayer.setSource(chatsClusterSource);

  mapStore.map.addLayer(chatsLayer);
};

const initMap = async () => {
  mapStore.map = new Map({
    
    target: mapView.value,
    layers: [
      // mapconfig.googlemap,
      mapconfig.streetmap,
      mapconfig.satellitemap,
      mapconfig.bcparcelmap,
      mapconfig.bookmarksmap,
    ],
    view: new View({
      center: olProj.transform(
        [mapconfig.x, mapconfig.y],
        "EPSG:4326",
        "EPSG:3857"
      ),
      zoom: mapconfig.zoom,
    }),
    controls: olControl.defaults({
      // rotateOptions: {autoHide:false},
      // Set to display OSM attributions on the bottom right control
      attributionOptions: {
        collapsible: true,
        collapsed: true,
      },
      zoom: false,
    }),
  });

  mapStore.map.on("singleclick", (evt) => {
    var reportVueMounted = false;
    var clickedFeature = mapStore.map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature) {
        return feature;
      }
    );
    if (clickedFeature && clickedFeature.featureType) {
      switch (clickedFeature.featureType) {
        case recentTransactions.featureTypeMarker:
        case recentTransactions.featureTypeCluster:
          if (!reportVueMounted) {
            mapStore.getRecentTransactions(clickedFeature);

            reportVueMounted = true;
          }
          router.push({ name: "transaction" });
          break;
        case featureChatsMarker:
        case featureChatsCluster:
          MapOnClick(clickedFeature.getGeometry().getCoordinates());
          break;
        default:
          console.log("Clicked on", clickedFeature.featureType);
      }
    } else {
      var icoordinate = evt.coordinate;
      MapOnClick(icoordinate);
    }
  });
};

const initPopup = () => {
  // Vienna marker
  popup = new Overlay({
    positioning: "center",
    element: popupRef.value,
    autoPan: true,
    autoPanAnimation: {
      duration: 250,
    },
    //offset: [-140 + 120, 0],
  });
  // console.log("popup", popup);
  mapStore.map.addOverlay(popup);
};

const showPopup = (coordinates) => {
  if (landMergerMode.value == false) popup.setPosition(coordinates);
};
const popupcloser = () => {
  popup.setPosition(undefined);
  //   closer.blur();
  return false;
};

const MapOnClick = (coordinate) => {
  // console.log("MapOnClick");

  mapStore.selectedArea = coordinate;

  // var point = new Point(mapStore.selectedArea);
  // mapStore.map.getView().fit(point, {
  //   maxZoom: mapconfig.zoom,
  //   duration: 1100,
  //   padding: [200, 80, 100, 0],
  //   callback: showPopup(olProj.transform(mapStore.lonlat, "EPSG:4326", "EPSG:3857"))
  // });

  propertyStore.property = null;
  ownerStore.owner = null;

  var url = mapconfig.bcparcelmap
    .getSource()
    .getFeatureInfoUrl(
      coordinate,
      mapStore.map.getView().getResolution(),
      mapStore.map.getView().getProjection(),
      {
        INFO_FORMAT: "application/json",
        propertyName:
          "PARCEL_FABRIC_POLY_ID,PARCEL_NAME,PIN,PID_NUMBER,PID,OWNER_TYPE,PARCEL_CLASS,PLAN_NUMBER,SHAPE",
      }
    );
  // console.log("url:" + url);
  url = proxyurl1 + url;

  // Ref: https://stackoverflow.com/questions/54042152/uncaught-in-promise-typeerror-cannot-set-property-playername-of-undefined-a/54042367
  axios.get(url).then(function (response) {
    
    if (
      response &&
      Object.prototype.hasOwnProperty.call(response.data, "features") &&
      response.data.features.length
    ) {

      console.log("selectedParcel, selectedParcel_id", response);
      var feature = response.data.features[0];
      selectedParcel.value = feature;
      // console.log("selectedParcel", feature, selectedParcel.value)
      mapStore.selectedParcel_id = feature.properties.PARCEL_FABRIC_POLY_ID;
      // selectedParcel_id.value=feature.properties.PARCEL_FABRIC_POLY_ID
      var g = feature.geometry; //get geometry of a featyre in your vector layer
      // custom hash code by object hash polygon coordinates

      let selectedPolygonCoordinates = g.coordinates[0];
      const objectHash = require("object-hash");
      mapStore.geoHash = objectHash(selectedPolygonCoordinates);
      socialStore.filterGeoHash = mapStore.geoHash;
      socialStore.filterPolygonCoordinates = selectedPolygonCoordinates;

      // Remove existing polygon
      mapStore.map.getLayers().forEach((layer) => {
        if (layer && layer.get("name") === "Selected Location") {
          //alert("Remove Vector Layer");
          mapStore.map.removeLayer(layer);
        }
      });

      // Set Parcel Color
      var parcelcolor = "blue";
      if (!feature.properties.PID) {
        //TO DO: GET ADDRESS from GEOCODER.CA e.g. http://geocoder.ca?latt=49.2631005&longt=-122.8852747&reverse=1&geoit=XML
        parcelcolor = "red";
      } else if (feature.properties.PARCEL_NAME == "Building Strata") {
        parcelcolor = "green"; // Build Strata but with a PID (one of the units) provided
      }

      mapStore.lonlat = olProj.transform(coordinate, "EPSG:3857", "EPSG:4326");
      var lon = mapStore.lonlat[0];
      var lat = mapStore.lonlat[1];
      // Use Bing Map API
      //var BingMapKey =
      //  "AnBk-_PACFpRa99Sgep3ZAS0y1co3qI0NvMcEJTWURpLTOof56yvVxjKQ15JhB5m";
      //var BingMapAPIURL =
      //  "https://dev.virtualearth.net/REST/v1/Locations/";

      var IQURL =
        "https://us1.locationiq.com/v1/reverse.php?key=6cdf16a403410b&lat=" +
        lat +
        " &lon=" +
        lon +
        "&addressdetails=1&format=json";

      // if (
      //   !feature.properties.PID ||
      //   feature.properties.PID == "Building Strata"
      // ) {
      if (
        !feature.properties.PARCEL_NAME ||
        feature.properties.PARCEL_NAME == "Building Strata"
      ) {
        axios
          //   .get(BingMapAPIURL + lat + "," + lon + "?key=" + BingMapKey)
          .get(proxyurl1 + IQURL)
          .then(async function (response) {
            console.log("Geocoding address", response);
            var mapAddress = response.data.address;
            if (mapAddress) {
              var road = "";
              var house_number = "";
              var name = "";
              if (mapAddress.road) {
                road = mapAddress.road;
              }
              if (mapAddress.house_number) {
                house_number = mapAddress.house_number;
              }
              if (mapAddress.name) {
                name = mapAddress.name;
              }

              mapAddress = house_number + " " + road;

              selectedAddress.value = mapAddress;
            }

            // modalStore.address = mapAddress + "(to be confirmed)";
            // modalStore.ownerName = "";
            // modalStore.ownerCompany = "";
            // modalStore.planNumber = "";

            mapStore.selectedParcel = selectedParcel.value;
            // mapStore.selectedCentre = selectedCentre.value;
            mapStore.selectedAddress = selectedAddress.value;

            popupContent.value = `<p>${mapAddress} <i>(to be confirmed)</i></p>`;
            // showPopup(coordinate);
            // popup.setPosition(coordinate);

            openModal();
          });
      } else {
        var props = feature.properties;
        var PID = props.PARCEL_NAME;

        mapStore.getParcelInfoByPID(PID).then(async function () {
          if (propertyStore.property) {
            // console.log(
            //   "$data.xProperty",
            //   propertyStore.property,
            //   ownerStore.owner
            // );
            // console.log("aProperty.Address:", propertyStore.property.Address);

            popupContent.value = `<p align="left">${propertyStore.displayAddressForModal(
              false
            )}</p><p align="left">${
              ownerStore.displayOwnerFullName
            }</p><p align="left">${ownerStore.owner.Company}</p>`;
            // popup.setPosition(coordinate);
            // showPopup(coordinate);

            selectedAddress.value = propertyStore.property.Address;

            // propertyStore.property = mapStore.property;

            // ownerStore.owner = mapStore.owner;

            // ownerinfo.value = ownerStore.owner;
            // propinfo.value = propertyStore.property;

            mapStore.selectedParcel = selectedParcel.value;
            // mapStore.selectedCentre = selectedCentre.value;
            mapStore.selectedAddress = selectedAddress.value;

            openModal();
          } else {
            axios.get(proxyurl1 + IQURL).then(async function (response) {
              // console.log("Geocoding address2", response);
              var mapAddress = response.data.address;
              if (mapAddress) {
                var road = "";
                var house_number = "";
                var name = "";
                if (mapAddress.road) {
                  road = mapAddress.road;
                }
                if (mapAddress.house_number) {
                  house_number = mapAddress.house_number;
                }
                if (mapAddress.name) {
                  name = mapAddress.name;
                }

                mapAddress = house_number + " " + road;

                selectedAddress.value = mapAddress;
              }

              // modalStore.address = mapAddress + "(to be confirmed)";
              // modalStore.ownerName = "";
              // modalStore.ownerCompany = "";
              // modalStore.planNumber = feature.properties.PLAN_NUMBER;

              mapStore.selectedParcel = selectedParcel.value;
              mapStore.selectedCentre = selectedCentre.value;
              mapStore.selectedAddress = selectedAddress.value;

              popupContent.value = `<p align="left">${mapAddress} <i>(to be confirmed)</i></p><p align="left">${feature.properties.PLAN_NUMBER}</p>`;
              // popup.setPosition(coordinate);
              // showPopup(coordinate);
              openModal();
            });
          }
        }); // end that.GetParcelInfoByPID
      } // end if else (feature.properties.PID)

      var styles = [
        new Style({
          stroke: new Stroke({
            color: parcelcolor,
            width: 3,
          }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
      ];

      landMergerStore.fetchVertices(
        g.coordinates,
        feature.properties.PID,
        (vertices) => {
          console.log("vertices", vertices);
          var polygon = new Polygon(vertices);
          selectedCentre.value = polygon.getInteriorPoint();

          // fix for parcel selected but cannot get blue polygon redraw. e.g. 25450 lougheed Maple Ridge
          if (!selectedCentre.value.flatCoordinates[0]) {
            selectedCentre.value = new Point(coordinate);
          }
          mapStore.selectedCentre = selectedCentre.value;
          //polygon.transform('EPSG:4326', 'EPSG:3857');
          // Create feature with polygon.
          var afeature = new Feature(polygon);
          // Create vector source and the feature to it.
          var vectorSource = new Vector();
          vectorSource.addFeature(afeature);
          // Create vector layer attached to the vector source.
          var vectorLayer = new VectorLayer({
            name: "Selected Location",
            source: vectorSource,
            style: styles,
          });
          // Add the vector layer to the map.
          mapStore.map.addLayer(vectorLayer);
        }
      );
    } // end of if (feature)
  }).finally(() => {
    var point = new Point(mapStore.selectedArea);
    const currentZoom = mapStore.map.getView().getZoom();
    mapStore.map.getView().fit(point, {
      maxZoom: currentZoom, // 
      // maxZoom: mapconfig.zoom,
      duration: 1100,
      padding: [200, 80, 100, 0],
      callback: showPopup(olProj.transform(mapStore.lonlat, "EPSG:4326", "EPSG:3857"))
    });
      // console.log('finally')
  //TODO: show popup affect map accuracy 2023-Jan-12
    // showPopup(olProj.transform(mapStore.lonlat, "EPSG:4326", "EPSG:3857"))

  }); // end of get(URL)

  
  // }  //end of if perry extent

  const flyTo = (location, done, view) => {
    const duration = 2000;
    const zoom = view.getZoom();
    let parts = 2;
    let called = false;
    function callback(complete) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        done(complete);
      }
    }
    view.animate(
      {
        center: location,
        duration: duration,
      },
      callback
    );
    view.animate(
      {
        zoom: zoom - 1,
        duration: duration / 2,
      },
      {
        zoom: zoom,
        duration: duration / 2,
      },
      callback
    );
  };

  // if (route.query && route.query.a != undefined) {
  //   flyTo(mapStore.selectedArea, () => {}, mapStore.map.getView());
  //   route.query.search = "false";
  //   // router.replace({
  //   //   ...router.currentRoute,
  //   // });
  // } else {
  // mapStore.map.getView().animate({
  //   zoom: mapconfig.zoom,
  //   center: mapStore.selectedArea,
  //   duration: 1200,
  // });
  // var point = new Point(mapStore.selectedArea);
  // mapStore.map.getView().fit(point, {
  //   maxZoom: mapconfig.zoom,
  //   duration: 1000,
  //   padding: [20, 0, 0, 310],
  // });
  // }
}; // end of MapOnClick

const openModal = async () => {
  // if (mapStore.property) socialStore.filterPostPid = mapStore.property?.Pid;

  // let iPID = mapStore.selectedParcel.properties.PID;

  // check is parcel bookmarked
  isBookmarked.value = bookmarkStore.checkIsBookmarked(mapStore.geoHash);


  try {
    socialStore.filterPostPid = mapStore.geoHash
    // iPID.substr(0, 3) + "-" + iPID.substr(3, 3) + "-" + iPID.substr(6);
  } catch (e) {
    // console.log(e, iPID)
    socialStore.filterPostPid = "";
  }
  if (landMergerMode.value == false) {
    mapStore.isSheetModalOpen = true;
  }
};

const cupertionDragEnd = (e) => {
  if (myPane.currentBreak() === "bottom") {
    showTopFloatingButton.value = true;
    showBottomFloatingButton.value = true;
  } else if (myPane.currentBreak() === "top") {
    showTopFloatingButton.value = false;
    showBottomFloatingButton.value = false;
  } else if (myPane.currentBreak() === "middle") {
    showTopFloatingButton.value = true;
    showBottomFloatingButton.value = false;
  }

  //   setTimeout(() => {
  //     if (window.innerHeight - e <= 136) {
  //       showTopFloatingButton.value = true;
  //       showBottomFloatingButton.value = true;
  //     } else if (window.innerHeight - e <= 336) {
  //       //middle
  //       showTopFloatingButton.value = true;
  //       showBottomFloatingButton.value = false;
  //     } else {
  //       //top
  //       showTopFloatingButton.value = false;
  //       showBottomFloatingButton.value = false;
  //     }
  //   }, 800);
};

// const dismissDrag = (e) => {
//   mapStore.isSheetModalOpen = false;
//   showBottomFloatingButton.value = true;
// };

// popup icon buttons
const showStreetviewPage = () => {
  router.push({ name: "streetview" });
};

const isBookmarkGroupModalOpen = ref(false)
const onBookmarkIconClick = () => {
  if (!bookmarkStore.checkIsBookmarked(mapStore.geoHash)) { //add bookmark
    isBookmarkGroupModalOpen.value = true;
  } else { //delete bookmark
    handleDeleteBookmark()
  }
}

const handleAddBookmark = async (bookmarkGroup) => {
  let bookmarkToAdd = {
      createdOn: new Date(),
      coordinates: JSON.stringify(mapStore.selectedParcel.geometry.coordinates),
      address: mapStore.selectedAddress,
      pid: mapStore.selectedParcel.properties.PID,
      parcel_id: mapStore.selectedParcel_id,
      lonlat: mapStore.lonlat,
      centre: mapStore.selectedCentre.flatCoordinates,
      geoHash: mapStore.geoHash, 
      groupId: bookmarkGroup.id
    };

    await bookmarkStore.addBookmark(bookmarkToAdd);
    isBookmarked.value = true;

    //render changes on bookmark layer
    var polygon = new Polygon(mapStore.selectedParcel.geometry.coordinates);
    var afeature = new Feature(polygon);
    afeature.setId(mapStore.geoHash);

    afeature.setStyle(new Style({
        fill: new Fill({
            color: bookmarkStore.getColorByGroupId(bookmarkGroup.id),
        }),
    }))


    mapStore.map.getLayers().forEach(function (lyr) {
      if (lyr.get("name") === "Bookmarks") {
        //lyr.getSource().addFeatures([feature])
        lyr.getSource().addFeature(afeature);
        //lyr.visible=true;
      }
    });
}
const handleDeleteBookmark = () => {
  bookmarkStore.deleteBookmark(mapStore.geoHash);
  isBookmarked.value = false;

  //render changes on bookmark layer    
  // var select = new Select();
  // select.getFeatures().clear();
  mapStore.map.getLayers().forEach(function (lyr) {
    if (lyr.get("name") === "Bookmarks") {
      //lyr.getSource().addFeatures([feature])
      var searchFeature = lyr
        .getSource()
        .getFeatureById(mapStore.geoHash);
      lyr.getSource().removeFeature(searchFeature);
    }
  });
}

const handleBookmarkGroupSelect = (bookmarkGroup) => {
  handleAddBookmark(bookmarkGroup);
  isBookmarkGroupModalOpen.value = false;
}

</script>
<style scoped>
ion-fab-button > ion-icon {
  font-size: 30px;
}
</style>
<style>

ion-searchbar {
  --background: white;
}

.ol-rotate {
  top: 7em;
}

.merge-panel {
  display: block;
  position: absolute;
  background: white;
  border: solid 1px black;
  margin-top: 3.2rem;
  margin-left: 1rem;
  z-index: 1000;
}
.map-search-result-list {
  margin-left: 1rem;
}
.searchbar-input.sc-ion-searchbar-ios {
  background-color: white !important;
  border: solid 1px grey;
}

.map-search-panel {
  display: block;
  position: absolute;
  width: 65%;
  z-index: 1000;
  padding-top: 1.5rem;
}
#mapView {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.sheetModalMap {
  --border-radius: 15px;
}

.cupertino-pane-wrapper {
  z-index: 80001;
}

.ol-popup {
  position: absolute;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 220px;
  font-size: 14px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: #fff;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.satellite {
  left: 0.5em;
  top: 7.5em;
}

.bookmark {
  left: 0.5em;
  top: 10em;
}

.locate {
  top: 12.5em;
  left: 0.5em;
}

.recentTrans {
  top: 15em;
  left: 0.5em;
}

.recentTrans-filter {
  top: 15em;
  left: 2.85em;
}

.ol-geocoder.gcd-txt-container {
  width: 15em;
  left: 3.3em;
}

.ol-geocoder .gcd-txt-control {
  height: 2.8em;
  top: 1.1em;
}

.mainmenu {
  position: absolute;
  top: 50%;
  left: 0.1em;
  margin: 3px 0;
}

/* ion-fab-list>ion-fab-button {
   width: 60px;
   height: 60px;
}
*/
ion-fab-button.small {
  width: 20px;
   height: 20px;
} 

.testbutton {
  position: absolute;
  top: 70%;
  left: 0.1em;
  margin: 3px 0;
}

.avatar {
  vertical-align: middle;
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.faIcon {
  padding-left: 4px;
  padding-right: 4px;
}

#concated-button-menu {
  margin-top: 15px;
}

.cupertino-pane-wrapper .pane {
  max-width: 100% !important;
  width: 100% !important;
}

.content {
  overflow-y: auto !important;
}

.cupertino-pane-wrapper .pane {
  max-width: 100%;
  width: 100%;
}
</style>
